<template>
  <div class="form-problem-modal">
    <h2 class="nomira mb-8 px-8 camembert">
      {{ title }}
    </h2>
    <section class="d-flex flex-direction-column px-8">
      <r-input
        label="Название проблемы"
        class="mb-6"
        v-model="model.name"
        :error="$v.model.name.$error"
        :error-message="mesErr($v.model.name)"
      />
      <!-- <r-form-item
        :error="$v.model.departmentId.$error"
        :message="mesErr($v.model.departmentId)">
        <r-autocomplete
          v-model="model.departmentId"
          idValue="id"
          textValue="name"
          :callback="searchDepartment"
          placeholder="Департамент"
        />
      </r-form-item> -->
      <r-textarea
        label="Описание"
        :rows="6"
        v-model="model.description"
      />
      <r-button
        @click="save"
        class="mt-8"
        width="wide"
        :title="button"
      />
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import Problem from '@/structure/entity/Problem';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { required } from '@/plugins/vuelidate/customValidate';

export default {
  name: 'FormProblemModal',
  mixins: [validationMixin, validateMixin],
  props: {
    item: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'Сохранить'
    }
  },
  data: vm => ({
    model: new Problem(vm.item)
  }),
  validations: {
    model: {
      name: {
        required: required('Обязательное поле')
      }
      // departmentId: {
      //   required: required('Обязательное поле')
      // }
    }
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$rir.modal.close({ ...this.model.getApiData, ...this.model });
    }
    // async searchDepartment (searchable) {
    //   const { data } = await this.$axios.get(
    //     `${getEnv('VUE_APP_API_SERVER')}ui/department`, { params: { searchable } }
    //   )
    //   return data
    // }
  }
};
</script>

<style lang="scss" scoped>
.form-problem-modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;

  &__wrapper {
    max-width: 800px;
    margin: auto;
  }
}
</style>
