var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags"},[_c('r-button-action',{staticClass:"tags__action-button mb-6",attrs:{"title":"Добавить","icon":"add"},on:{"click":_vm.openAddModal}}),_c('div',{staticClass:"tags__search mb-8"},[_c('r-input',{attrs:{"label":"Поиск","fill":"rocky"},on:{"input":_vm.debounceInput},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}})],1),_c('table-component',{attrs:{"saving":_vm.saving,"loading":_vm.isLoadPage,"columns":_vm.columns,"empty-details":"Вы можете добавить новую метку","list":_vm.tagsList,"save-button":false,"sort-mode":true,"sort-fields":{
      name: true,
      updatedAt: true,
      'tagCategory.name': true,
      'tagCategory.description': true,
    }},on:{"edit":_vm.editForm,"delete":_vm.deleteItem},scopedSlots:_vm._u([{key:"rowData",fn:function({ item, column }){return [(column.key === 'updatedAt')?_c('div',{staticClass:"tags__table-item"},[_c('p',{staticClass:"feta mb-1"},[_vm._v(" "+_vm._s(_vm._f("dateMonthYear")(item[column.key]))+" ")]),_c('p',{staticClass:"mozarella anie"},[_vm._v(" "+_vm._s(_vm._f("time")(item[column.key]))+" ")])]):(column.key === 'color')?_c('div',{staticClass:"tags__table-item"},[_c('div',{staticClass:"circle",style:(`background-color: ${item[column.key]}`)})]):(column.key === 'tagCategory.name')?_c('div',{staticClass:"tags__table-item"},[_vm._v(" "+_vm._s(item.tagCategory.name)+" ")]):(column.key === 'tagCategory.description')?_c('div',{staticClass:"tags__table-item"},[_c('r-shorter',{attrs:{"text":item.tagCategory.description || '—',"count-row":3,"tooltip-options":{
            position: 'center-left',
            alignTitle: 'start',
            maxWidth: '300px',
          }}})],1):_c('div',{staticClass:"tags__table-item"},[_c('r-shorter',{attrs:{"text":item[column.key] || '—',"content-class":null,"count-row":1,"tooltip-options":{
            position: 'center-left',
            alignTitle: 'start',
            maxWidth: '300px',
          }}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }