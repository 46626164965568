<template>
  <div class="toolbar-dashboard">
    <section class="d-flex flex-direction-column">
      <h6 class="nomira mb-2 camembert">
        Актуальная сводка по использованию прикладных приложений
      </h6>
      <span class="titanic--text naise anie mb-1">по состоянию на</span>
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-5">
          <r-icon
            size="16"
            icon="calendar"
            fill="rocky"
            class="mr-2"
          />
          <span class="metioche titanic--text anie">{{ date | dateTextMonth }}</span>
        </div>
        <div class="d-flex align-items-center">
          <r-icon
            size="16"
            icon="clock"
            fill="rocky"
            class="mr-2"
          />
          <span class="metioche titanic--text anie">{{ date | time }}</span>
        </div>
      </div>
    </section>
    <user-info />
  </div>
</template>

<script>
import UserInfo from '@/layout/components/UserInfo';

export default {
  name: 'ToolbarDashboard',
  components: { UserInfo },
  data: () => ({
    date: new Date()
  })
};
</script>

<style lang="scss" scoped>
.toolbar-dashboard {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
