<template>
  <div class="departments">
    <div class="departments__action-button mb-6">
      <r-button-action
        title="Добавить"
        icon="add"
        @click.native="openAddModal"
      />
    </div>
    <table-component
      :is-search="true"
      search-field="name"
      :saving="saving"
      :loading="isLoadPage"
      :columns="columns"
      empty-details="Вы можете добавить новый департамент"
      :list="departamentsList"
      @edit="editForm"
      @delete="deleteItem"
      @save="saveDepartments"
      draggable
    >
      <template #rowData="{ item, column }">
        <div
          v-if="column.key === 'description'"
          class="table-item"
        >
          <r-shorter
            :text="item[column.key] || ''"
            :count-row="3"
            :tooltip-options="{
                position: 'center-left',
                alignTitle: 'start',
                maxWidth: '300px',
              }"
          />
        </div>
        <div
          v-else
          class="table-item"
        >
          {{ item[column.key] }}
        </div>
      </template>
    </table-component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TableComponent from '@/components/TableComponent';
import Department from '@/structure/entity/Department';
import FormDepartmentModal from './components/FormDepartmentModal';

export default {
  name: 'Departments',
  components: { TableComponent },
  data: () => ({
    isLoadPage: false,
    columns: [
      {
        title: 'Краткое название',
        key: 'name',
        font: 'bryndza',
        width: 1
      },
      {
        title: 'Полное название',
        key: 'fullName',
        font: 'mozzarella',
        width: 2
      },
      {
        title: 'Описание',
        key: 'description',
        font: 'mozzarella',
        width: 3
      }
    ],
    saving: false
  }),
  computed: {
    ...mapState('departments', ['departments']),
    departamentsList() {
      return this.departments?.list || [];
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'slides',
        ghostClass: 'departments__item--ghost',
        chosenClass: 'departments__item--chosen',
        forceFallback: true
      };
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions('departments', ['changeDepartamentsList', 'getDepartmentsList']),
    async loadData() {
      this.isLoadPage = true;
      await this.getDepartmentsList();
      this.isLoadPage = false;
    },
    async openAddModal() {
      const res = await this.$root.$modal.openModal(FormDepartmentModal, {
        item: new Department(),
        title: 'Новый департамент',
        button: 'Добавить'
      });
      if (typeof res?.id !== 'undefined') this.departments.addListEntity(res);
    },
    async editForm(item) {
      const res = await this.$rir.modal.open(FormDepartmentModal, {
        item,
        title: item.name,
        button: 'Редактировать'
      });
      if (typeof res.id !== 'undefined') item.updateData(res);
    },
    deleteItem(item) {
      this.departments.removeListEntity(item);
    },
    async saveDepartments() {
      this.saving = true;
      await this.changeDepartamentsList(this.departamentsList)
        .then(() => {
          this.$rir.notification.send({
            title: 'Список депертаментов успешно сохранен!',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.departments {
  &__report {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  &__action-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    //padding: 0 32px;

    span {
      color: var(--rir-rocky)
    }

    &:hover {
      &::v-deep {
        & path {
          fill: var(--rir-rocky--hover);
        }
      }

      span {
        color: var(--rir-rocky--hover);
      }
    }
  }

  &__reaction-time {
    padding-right: 16px;
  }
}
</style>
