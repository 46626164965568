var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"department"},[_c('div',{staticClass:"department__wrapper"},[_c('div',{staticClass:"department__header"},[_c('div',{staticClass:"department__title mb-6"},[_c('h2',{staticClass:"mb-1 camembert"},[_vm._v(" "+_vm._s(_vm.department.name)+" ")]),_c('span',{staticClass:"anie"},[_c('r-shorter',{attrs:{"text":_vm.department.description || '',"count-row":5,"tooltip-options":{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }}})],1)]),_c('r-button-action',{staticClass:"mb-6",attrs:{"title":"Добавить тип проблемы","icon":"add"},nativeOn:{"click":function($event){return _vm.openAddModal.apply(null, arguments)}}})],1),_c('table-component',{attrs:{"is-search":true,"search-field":"name","saving":_vm.saving,"columns":_vm.columns,"loading":_vm.isLoadPage,"list":_vm.problemsList,"empty-details":"Вы можете добавить новый тип проблемы","draggable":""},on:{"edit":_vm.editForm,"delete":_vm.deleteItem,"save":_vm.saveProblems},scopedSlots:_vm._u([{key:"rowData",fn:function({ item, column }){return [(column.key === 'description')?_c('div',{staticClass:"table-item"},[_c('r-shorter',{attrs:{"text":item[column.key] || '',"count-row":3,"tooltip-options":{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }}})],1):_c('div',{staticClass:"table-item"},[_vm._v(" "+_vm._s(item[column.key])+" ")])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }