import Entity from '@/structure/entity/Entity'

export default class Keyword extends Entity {
  tags = null

  constructor (data = {}) {
    super()
    data && this.updateData(data)
  }
}
