var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[(_vm.isSearch)?_c('r-input',{staticClass:"mb-6",attrs:{"label":"Поиск","before-icon":"search","size":"eluno","is-clear-model-string":true},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e(),_c('div',{staticClass:"table__wrapper"},[_c('div',{staticClass:"table__header",class:{ 'table__header--shadowed': _vm.scroll }},[(_vm.draggable)?_c('p',{staticClass:"table__position burger-column mozzarella anie"},[_vm._v(" Позиция в списке ")]):_vm._e(),_vm._l((_vm.columns),function(column){return _c('p',{key:column.key,staticClass:"table__position mozzarella d-flex align-items-center pr-4",class:{
          'has_sorted ekas': _vm.sortMode ? _vm.sortFields[column.key] : null,
          anie: (_vm.sortMode ? !_vm.sortFields[column.key] : null) || !_vm.sortMode,
        },style:(`flex: ${column.width}`),on:{"click":function($event){_vm.sortMode
          ? (_vm.sortFields[column.key] ? _vm.sortBy(column.key) : null)
          : null}}},[_vm._v(" "+_vm._s(column.title)+" "),(_vm.sortMode ? _vm.sortFields[column.key] : null)?_c('span',{staticClass:"table_sort d-flex flex-direction-column justify-content-center ml-2"},[_c('r-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"icon":"arrow-up","size":"8","fill":(_vm.sort.key === column.key && _vm.sort.isAsc === true) ? 'rocky' : 'memento'}}),_c('r-icon',{attrs:{"icon":"arrow-down","size":"8","fill":(_vm.sort.key === column.key && _vm.sort.isAsc === false) ? 'rocky' : 'memento'}})],1):_vm._e()])}),_c('div',{staticClass:"actions-column"})],2),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center pa-4"},[_c('r-spinner')],1):_c('draggable',_vm._b({ref:"table",staticClass:"table__items",attrs:{"draggable":_vm.draggable ? '.table__item' : '',"list":_vm.list}},'draggable',_vm.dragOptions,false),_vm._l((_vm.sortedItems),function(item,index){return _c('div',{key:item.id,staticClass:"table__item"},[(_vm.draggable)?_c('div',{staticClass:"d-flex align-items-center burger-column"},[_c('r-icon',{staticClass:"table__item-burger",attrs:{"size":"16","icon":"menu","fill":"titanic"}}),_c('span',{staticClass:"feta anie ml-6"},[_vm._v(_vm._s(index + 1))])],1):_vm._e(),_vm._l((_vm.columns),function(column){return _c('div',{key:column.key,staticClass:"d-flex align-items-center pr-4",class:column.font,style:(`flex: ${column.width}`)},[(_vm.$scopedSlots.rowData)?_vm._t("rowData",null,{"item":item,"column":column}):_c('r-shorter',{attrs:{"text":item[column.key] || '',"content-class":null,"count-row":1,"tooltip-options":{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }}})],2)}),_c('div',{staticClass:"table__item-actions actions-column d-flex align-items-center"},[_c('r-icon',{staticClass:"table__item-btn",attrs:{"fill":"rocky","size":"16","icon":"edit"},nativeOn:{"click":function($event){return _vm.$emit('edit', item)}}}),_c('r-icon',{staticClass:"table__item-btn ml-8",attrs:{"size":"16","icon":"delete","fill":"fargo"},nativeOn:{"click":function($event){return _vm.$emit('delete', item)}}})],1)],2)}),0),(_vm.showEmpty)?_c('empty',{staticClass:"mt-8",attrs:{"title":_vm.emptyTitle,"details":_vm.emptyDetails}}):_vm._e()],1),(_vm.saveButton)?_c('div',{staticClass:"table__footer",class:{ 'table__footer--opened': _vm.isSidebarOpen }},[(_vm.searchText)?_c('r-tooltip',{attrs:{"activator-hover":"","color":"amelie","position":"top-center","is-arrow":true,"align-title":"start","title":"Очистите строку поиска, прежде чем сохранить список"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('r-button',{attrs:{"disabled":"","width":"wide","title":"Сохранить"}})]},proxy:true}],null,false,2317285700)}):_c('r-button',{attrs:{"is-loading":_vm.saving,"disabled":_vm.saving,"width":"wide","title":"Сохранить"},on:{"click":function($event){return _vm.$emit('save')}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }