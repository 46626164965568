import { helpers } from 'vuelidate/lib/validators'
export const required = (mesErr) =>
  helpers.withParams(
    { type: 'required', mesErr: mesErr },
    (value) => !!value && helpers.req(value)
  )
export const regEx = (regEx, mesErr) =>
  helpers.withParams(
    { type: 'regEx', mesErr: mesErr },
    (value) => !!value && regEx.test(value)
  )
export const typeFloat = (mesErr) =>
  helpers.withParams(
    { type: 'typeFloat', mesErr: mesErr },
    // eslint-disable-next-line eqeqeq
    (value) => !!value && Number(value) == value && value % 1 !== 0
  )
export const minLength = (countLength, mesErr) =>
  helpers.withParams(
    { type: 'minLength', mesErr: mesErr },
    (value) => !!value && value.length >= countLength
  )
export const apiCheck = (action, mesErr) =>
  helpers.withParams(
    { type: 'apiCheck', mesErr: mesErr },
    async () => {
      const res = await action()
      return res
    }
  )
