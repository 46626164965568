<template>
  <section class="user">
    <r-icon
      style="cursor: pointer"
      icon="exit"
      size="16"
      class="mr-5"
      @click.native="logout"
    />
    <div class="user__avatar">
      <r-icon
        fill="amelie"
        icon="avatar"
        size="20"
      />
    </div>
    <span>{{ infoName }}</span>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserInfo',
  computed: {
    ...mapGetters('session', ['infoName'])
  },
  methods: {
    logout() {
      this.$keycloak.logoutFn();
    }
  }
};
</script>

<style lang="scss" scoped>
.user{
  display: flex;
  align-items: center;
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--rir-alien);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }
  & > span {
    font-weight: 500;
  }
}
</style>
