export default class Entity {
  id = null;
  name = null;
  description = null;

  updateData(data = {}) {
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this[key] = data[key];
      }
    }
  }

  get getApiData() {
    const dataApi = {};
    for (const key in this) {
      if (this[key] !== null || this[key] !== undefined) {
        dataApi[key] = this[key];
      }
    }
    return dataApi;
  }
}
