<template>
  <section class="users-bot-item">
    <span class="users-bot-item__name">{{ executor.fullName }}</span>
    <div class="users-bot-item__detail">
      <!--      <span>Telegram: <span>@NickName</span></span>-->
      <span>Департамент: <span>{{ department.fullName }}</span></span>
      <span>Email: <span>{{ executor.email || 'Не указан' }}</span></span>
      <span>Телефон: <span>{{ executor.phone || 'Не указан' }}</span></span>
    </div>
    <div class="users-bot-item__actions">
      <r-tooltip
        activator-hover
        title="Редактировать"
      >
        <template #activator>
          <r-button-simple
            size="larishae"
            icon="edit"
            @click="openEditModal"
          />
        </template>
      </r-tooltip>
      <r-tooltip
        activator-hover
        v-if="executor.enabled"
        title="Заблокировать"
      >
        <template #activator>
          <r-button-simple
            size="larishae"
            :loading="isLoadingEnabled"
            @click.native="changeEnabled"
            icon="close"
            type="primary"
            color="fargo"
          />
        </template>
      </r-tooltip>
      <r-tooltip
        activator-hover
        v-else
        title="Активировать"
      >
        <template #activator>
          <r-button-simple
            size="larishae"
            :loading="isLoadingEnabled"
            @click.native="changeEnabled"
            icon="selected"
            color="matrix"
          />
        </template>
      </r-tooltip>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UserBotEditModal from '@/views/usersBot/components/UserBotEditModal';

export default {
  name: 'UsersBotItem',
  props: {
    executor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoadingEnabled: false
  }),
  computed: {
    ...mapState('departments', ['departments']),
    department() {
      return this.departments?.list?.find(el => el.id === this.executor.departmentId) || {
        fullName: 'Департамент не выбран'
      };
    }
  },
  mounted() {
    this.getDepartmentsList();
  },
  methods: {
    ...mapActions('executors', ['changeEnabledUsersBot']),
    ...mapActions('departments', ['getDepartmentsList']),
    changeEnabled() {
      this.isLoadingEnabled = true;
      this.changeEnabledUsersBot(this.executor)
        .finally(() => {
          this.isLoadingEnabled = false;
        });
    },
    openEditModal() {
      this.$rir.modal.open(UserBotEditModal, {
        executor: this.executor
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.users-bot-item{
  background-color: var(--rir-rush);
  border-radius: 16px;
  padding: 16px 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  &__name{
    flex: 0 0 200px;
    width: 200px;
    flex-wrap: wrap;
    margin-right: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--rir-gladiator);
    overflow-wrap: break-word;
  }
  &__detail{
    flex: 1 1 auto;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    & > span {
      & > span {
        font-weight: bold;
      }
    }
  }
  &__actions{
    display: flex;
    align-items: center;
    & > * {
      margin-right: 12px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
