import Vue from 'vue';
import VueRouter from 'vue-router';
import getEnv from '@/plugins/env';
import DefaultLayout from '@/layout/DefaultLayout';
import UsersBotPage from '@/views/usersBot/UsersBotPage';
import Dashboard from '@/views/dashboard/Dashboard';
import ToolbarDashboard from '@/layout/toolbars/ToolbarDashboard';
import ToolbarDirectories from '@/layout/toolbars/ToolbarDirectories';
import ToolbarEntity from '@/layout/toolbars/ToolbarEntity';
import Directories from '@/views/directories/Directories';
import DepartmentView from '@/views/directories/Problems/DepartmentView';
// import ProblemsPage from '@/views/Propblem/ProblemsPage'

const showSummary = parseInt(getEnv('VUE_APP_SHOW_SUMMARY'));
const showKeywords = parseInt(getEnv('VUE_APP_SHOW_KEYWORDS'));
const showTags = parseInt(getEnv('VUE_APP_SHOW_TAGS'));

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: showSummary ? 'dashboard' : 'departaments' }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    components: {
      default: Dashboard,
      toolbar: ToolbarDashboard
    },
    meta: {
      layout: DefaultLayout
    },
    beforeEnter: (to, from, next) => {
      showSummary ? next() : next('/departaments');
    }
  },
  {
    path: '/directories',
    name: 'directories',
    components: {
      default: Directories,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Справочники'
    }
  },
  {
    path: '/problems/department/:id',
    name: 'department',
    components: {
      default: DepartmentView,
      toolbar: ToolbarEntity
    },
    meta: {
      layout: DefaultLayout,
      tab: 'problems'
    }
  },
  {
    path: '/departaments',
    name: 'departaments',
    components: {
      default: Directories,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Департаменты',
      breadcrumbs: [
        {
          title: 'Справочники'
        }
      ]
    }
  },
  {
    path: '/problems',
    name: 'problems',
    components: {
      default: Directories,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Типы проблем',
      breadcrumbs: [
        {
          title: 'Справочники'
        }
      ]
    }
  },
  {
    path: '/keywords',
    name: 'keywords',
    components: {
      default: Directories,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Ключевые слова',
      breadcrumbs: [
        {
          title: 'Справочники'
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      showKeywords ? next() : next('/departaments');
    }
  },
  {
    path: '/tags',
    name: 'tags',
    components: {
      default: Directories,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Метки',
      breadcrumbs: [
        {
          title: 'Справочники'
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      showTags ? next() : next('/tags');
    }
  },
  {
    path: '/tag-categories',
    name: 'tag-categories',
    components: {
      default: Directories,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Категории меток',
      breadcrumbs: [
        {
          title: 'Справочники'
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      showTags ? next() : next('/tag-categories');
    }
  },
  {
    path: '/users-bot',
    name: 'users-bot',
    components: {
      default: UsersBotPage,
      toolbar: ToolbarDirectories
    },
    meta: {
      layout: DefaultLayout,
      title: 'Исполнители'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: getEnv('BASE_URL'),
  routes
});

export default router;
