export default class ListEntities {
  list = [];
  Entity = () => ({});

  constructor(data = {
    list: [],
    entity: null
  }) {
    if (data.entity) {
      this.Entity = data.entity;
      this.Entity.prototype.removeListEntity = this.removeListEntity.bind(this);
      this.list = data.list.map(el => new this.Entity(el));
    }
  }

  removeListEntity(entity) {
    this.list.splice(this.list.findIndex(el => el.id === entity.id), 1);
  }

  addListEntity(entity) {
    this.list.push(new this.Entity(entity));
  }

  nextData(data) {
    this.list = this.list.concat(data.map(el => new this.Entity(el)));
  }
}
