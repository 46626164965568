export default {
  async getUsersBot ({ rootGetters, commit }, payload) {
    const executors = await rootGetters.GET('executor', payload)
    commit('setProperty', { name: 'executors', value: executors })
  },
  async updateUsersBot ({ rootGetters, commit }, payload) {
    await rootGetters.POST(`executor/${payload.id}`, payload)
    commit('updateEntityArray', {
      name: 'executors',
      value: {
        ...payload,
        fullName: `${payload.firstName} ${payload.secondName} ${payload.lastName}`
      }
    })
  },
  async changeEnabledUsersBot ({ rootGetters, commit }, payload) {
    await rootGetters.POST(`executor/${payload.id}`, { ...payload, enabled: !payload.enabled })
    commit('removeEntityArray', { name: 'executors', value: payload.id })
  }
}
