var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-categories"},[_c('r-button-action',{staticClass:"tag-categories__action-button mb-6",attrs:{"title":"Добавить","icon":"add"},on:{"click":_vm.openAddModal}}),_c('div',{staticClass:"tag-categories__search mb-8"},[_c('r-input',{attrs:{"label":"Поиск"},on:{"input":_vm.debounceInput},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}})],1),_c('table-component',{attrs:{"saving":_vm.saving,"loading":_vm.isLoadPage,"columns":_vm.columns,"empty-details":"Вы можете добавить новую категорию","list":_vm.tagCategoriesList,"save-button":false,"sort-mode":true,"sort-fields":{
      name: true,
      description: true,
      tagsCount: true,
      updatedAt: true,
    }},on:{"edit":_vm.editForm,"delete":_vm.deleteItem},scopedSlots:_vm._u([{key:"rowData",fn:function({ item, column }){return [(column.key === 'name')?_c('div',{staticClass:"tag-categories__table-item"},[_c('r-shorter',{attrs:{"text":item[column.key] || '—',"count-row":1,"tooltip-options":{
            position: 'center-left',
            alignTitle: 'start',
            maxWidth: '300px',
          }}})],1):_vm._e(),(column.key === 'updatedAt')?_c('div',{staticClass:"tag-categories__table-item"},[_c('p',{staticClass:"feta mb-1"},[_vm._v(" "+_vm._s(_vm._f("dateMonthYear")(item.updatedAt))+" ")]),_c('p',{staticClass:"mozarella anie"},[_vm._v(" "+_vm._s(_vm._f("time")(item.updatedAt))+" ")])]):(column.key === 'description')?_c('div',{staticClass:"tag-categories__table-item"},[_c('r-shorter',{attrs:{"text":item[column.key] || '—',"count-row":3,"tooltip-options":{
            position: 'center-left',
            alignTitle: 'start',
            maxWidth: '300px',
          }}})],1):_c('div',{staticClass:"tag-categories__table-item"},[_vm._v(" "+_vm._s(item[column.key])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }