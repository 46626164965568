import Entity from '@/structure/entity/Entity'

export default class Problem extends Entity {
  departmentId = null
  departmentName = null

  constructor (data = {}) {
    super()
    data && this.updateData(data)
  }
}
