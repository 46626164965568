<template>
  <section class="user-bot-edit-modal">
    <div class="user-bot-edit-modal__header">
      <span class="user-bot-edit-modal__title">Редактирование пользователя</span>
    </div>
    <div class="user-bot-edit-modal__body">
      <r-input
        label="Фамилия"
        v-model.lazy="$v.model.lastName.$model"
        :error="$v.model.lastName.$error"
        :error-message="mesErr($v.model.lastName)"
      />
      <r-input
        class="mt-6"
        label="Имя"
        v-model.lazy="$v.model.firstName.$model"
        :error="$v.model.firstName.$error"
        :error-message="mesErr($v.model.firstName)"
      />
      <r-input
        class="mt-6"
        label="Отчество"
        v-model.lazy="$v.model.secondName.$model"
        :error="$v.model.secondName.$error"
        :error-message="mesErr($v.model.secondName)"
      />
      <r-input
        class="mt-6"
        label="Email"
        v-model.lazy="$v.model.email.$model"
        :error="$v.model.email.$error"
        :error-message="mesErr($v.model.email)"
      />
      <r-input
        class="mt-6"
        label="Телефон"
        v-model.lazy="$v.model.phone.$model"
        v-mask="'+7XXXXXXXXXX'"
        :error="$v.model.phone.$error"
        :error-message="mesErr($v.model.phone)"
      />
      <r-select
        is-search
        label="Департамент"
        v-model="model.departmentId"
        id-value="id"
        title-value="name"
        :error="$v.model.departmentId.$error"
        :error-message="mesErr($v.model.departmentId)"
        :items="departments.list"
        @click="searchDepartment"
      />
    </div>
    <div class="user-bot-edit-modal__actions">
      <r-button
        :loading="isLoading"
        @click="saveActions"
        title="Сохранить"
        width="wide"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { regEx, required } from '@/plugins/vuelidate/customValidate';

export default {
  name: 'UserBotEditModal',
  directives: { mask },
  mixins: [validationMixin, validateMixin],
  data: () => ({
    isLoading: false,
    model: null
  }),
  created() {
    this.model = this.executor;
  },
  validations: {
    model: {
      firstName: {
        required: required('Обязательное поле')
      },
      secondName: {
        required: required('Обязательное поле')
      },
      lastName: {
        required: required('Обязательное поле')
      },
      email: {
        required: required('Обязательное поле'),
        regEx: regEx(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Не верный формат Email')
      },
      phone: {
        required: required('Обязательное поле'),
        regEx: regEx(/^\+7[0-9]{3}\)?[-. ]?([0-9]{3})[-]?([0-9]{2})[-]?([0-9]{2})$/, 'Формат телефона +X (XXX) XXX-XX-XX')
      },
      departmentId: {
        required: required('Обязательное поле')
      }
    }
  },
  props: {
    executor: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('departments', ['departments'])
  },
  methods: {
    ...mapActions('executors', ['updateUsersBot']),
    ...mapActions('departments', ['getDepartmentsList']),
    saveActions() {
      this.$v.$touch();
      if (this.$v.$invalid || this.isLoading) return;
      this.isLoading = true;
      this.updateUsersBot(this.model)
        .finally(() => {
          this.isLoading = false;
          this.$rir.modal.close();
        });
    },
    async searchDepartment() {
      await this.getDepartmentsList();
      return this.departments?.list;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-bot-edit-modal{
  padding: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  &__close{
    position: absolute;
    top: -28px;
    right: -28px;
  }
  &__header{
    margin-bottom: 12px;
  }
  &__title{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--rir-gladiator);
  }
  &__body{
    margin-bottom: 12px;
    & > * {
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
</style>
