<template>
  <div class="table">
    <r-input
      v-if="isSearch"
      label="Поиск"
      before-icon="search"
      size="eluno"
      :is-clear-model-string="true"
      class="mb-6"
      v-model="searchText"
    />
    <div class="table__wrapper">
      <div
        class="table__header"
        :class="{ 'table__header--shadowed': scroll }"
      >
        <p
          v-if="draggable"
          class="table__position burger-column mozzarella anie"
        >
          Позиция в списке
        </p>
        <p
          v-for="column in columns"
          :key="column.key"
          :style="`flex: ${column.width}`"
          class="table__position mozzarella  d-flex align-items-center pr-4"
          :class="{
            'has_sorted ekas': sortMode ? sortFields[column.key] : null,
            anie: (sortMode ? !sortFields[column.key] : null) || !sortMode,
          }"
          @click="sortMode
            ? (sortFields[column.key] ? sortBy(column.key) : null)
            : null"
        >
          {{ column.title }}
          <span
            class="table_sort d-flex flex-direction-column justify-content-center ml-2"
            v-if="sortMode ? sortFields[column.key] : null"
          >
            <r-icon
              icon="arrow-up"
              size="8"
              :fill="(sort.key === column.key && sort.isAsc === true) ? 'rocky' : 'memento'"
              style="margin-bottom: 2px"
            />
            <r-icon
              icon="arrow-down"
              size="8"
              :fill="(sort.key === column.key && sort.isAsc === false) ? 'rocky' : 'memento'"
            />
          </span>
        </p>
        <div class="actions-column" />
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center pa-4"
      >
        <r-spinner />
      </div>
      <draggable
        v-else
        class="table__items"
        :draggable="draggable ? '.table__item' : ''"
        v-bind="dragOptions"
        :list="list"
        ref="table"
      >
        <div
          v-for="(item, index) in sortedItems"
          :key="item.id"
          class="table__item"
        >
          <div
            class="d-flex align-items-center burger-column"
            v-if="draggable"
          >
            <r-icon
              size="16"
              class="table__item-burger"
              icon="menu"
              fill="titanic"
            />
            <span class="feta anie ml-6">{{ index + 1 }}</span>
          </div>
          <div
            v-for="column in columns"
            :key="column.key"
            class="d-flex align-items-center pr-4"
            :class="column.font"
            :style="`flex: ${column.width}`"
          >
            <slot
              v-if="$scopedSlots.rowData"
              name="rowData"
              :item="item"
              :column="column"
            />

            <r-shorter
              v-else
              :text="item[column.key] || ''"
              :content-class="null"
              :count-row="1"
              :tooltip-options="{
                position: 'center-left',
                alignTitle: 'start',
                maxWidth: '300px',
              }"
            />
          </div>
          <div class="table__item-actions actions-column d-flex align-items-center">
            <r-icon
              fill="rocky"
              size="16"
              class="table__item-btn"
              icon="edit"
              @click.native="$emit('edit', item)"
            />
            <r-icon
              size="16"
              class="table__item-btn ml-8"
              icon="delete"
              fill="fargo"
              @click.native="$emit('delete', item)"
            />
          </div>
        </div>
      </draggable>
      <empty
        v-if="showEmpty"
        class="mt-8"
        :title="emptyTitle"
        :details="emptyDetails"
      />
    </div>
    <div
      class="table__footer"
      :class="{ 'table__footer--opened': isSidebarOpen }"
      v-if="saveButton"
    >
      <r-tooltip
        v-if="searchText"
        activator-hover
        color="amelie"
        position="top-center"
        :is-arrow="true"
        align-title="start"
        title="Очистите строку поиска, прежде чем сохранить список"
      >
        <template #activator>
          <r-button
            disabled
            width="wide"
            title="Сохранить"
          />
        </template>
      </r-tooltip>
      <r-button
        v-else
        @click="$emit('save')"
        :is-loading="saving"
        :disabled="saving"
        width="wide"
        title="Сохранить"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import Empty from './Empty';

export default {
  name: 'TableComponent',
  components: { draggable, Empty },
  props: {
    list: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    emptyTitle: {
      type: String,
      default: 'Данные отсутствуют'
    },
    emptyDetails: {
      type: String,
      default: ''
    },
    draggable: {
      type: Boolean,
      default: false
    },
    sortMode: {
      type: Boolean,
      default: false
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    sortFields: {
      type: Object,
      default: () => {}
    },
    searchField: {
      type: String,
      default: 'name'
    },
    saveButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scroll: false,
      table: null,
      sort: {
        key: '',
        isAsc: true
      },
      searchText: '',
      tableList: []
    };
  },
  computed: {
    ...mapState('apps', ['isSidebarOpen']),
    showEmpty() {
      return !this.list?.length && !this.loading;
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'slides',
        ghostClass: 'table__item--ghost',
        chosenClass: 'table__item--chosen',
        forceFallback: true
      };
    },
    sortedItems() {
      let list = this.list.slice();

      if (this.sort.key) {
        const separateKey = this.sort.key.split('.');
        const len = separateKey.length;
        list.sort((a, b) => {
          let i = 0;
          while (i < len) {
            a = a[separateKey[i]];
            b = b[separateKey[i]];
            i++;
          }
          if (typeof a === 'string' && typeof b === 'string') {
            a = a.toLowerCase();
            b = b.toLowerCase();
          }
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1);
        });
      }
      if (this.searchText) {
        list = list.filter(
          el => this.wordMatch(el[this.searchField] || '', this.searchText) <= 0.5
        );
      }

      return list;
    }
  },
  watch: {
    loading(val) {
      if (!val) this.setScrollHandler();
    }
  },
  beforeDestroy() {
    this.table.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    wordMatch(left, right) {
      // 0 - полное совпадение
      // 1 - нет совпадений
      left = left?.toLowerCase();
      right = right?.toLowerCase();

      if (left.includes(right)) {
        return 0;
      }

      left = `\b\b${left.toLowerCase()}\f\f`;
      right = `\b\b${right.toLowerCase()}\f\f`;

      let dist = -4;

      for (let i = 0; i < left.length - 2; ++i) {
        if (!right.includes(left.slice(i, i + 3))) ++dist;
      }

      for (let i = 0; i < right.length - 2; ++i) {
        if (!left.includes(right.slice(i, i + 3))) ++dist;
      }

      return Math.max(0, dist) / (left.length + right.length - 8);
    },
    sortBy(key) {
      if (this.sort.key === key && this.sort.isAsc === false) {
        this.sort.isAsc = true;
        this.sort.key = '';
      } else {
        this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : true;
        this.sort.key = key;
      }
    },
    scrollHandler() {
      this.scroll = this.table.scrollTop;
    },
    setScrollHandler() {
      this.$nextTick(() => {
        this.table = this.$refs.table.$el;
        this.table.addEventListener('scroll', this.scrollHandler);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  &__wrapper {
    width: 100%;
    padding-bottom: 96px;
  }

  &__header {
    width: 100%;
    position: relative;
    padding: 16px 0;
    display: flex;
    align-items: center;
    transition: box-shadow 0.3s;

    &--shadowed {
      box-shadow: 0px 12px 16px -4px rgba(4, 21, 62, 0.08);
    }
  }

  &__position {
   // margin-top: auto;
    &.has_sorted{
      cursor: pointer;
    }
  }

  &__items {
    width: 100%;
    max-height: calc(100vh - 268px);
    overflow: scroll;
  }

  &__item {
    width: 100%;
    display: flex;
    min-height: 72px;
    padding: 16px 0;
    border-top: 1px solid var(--rir-arrival);

    &--ghost {
      background: var(--rir-rocky);
      border-radius: 8px;
      opacity: 0.08;
    }

    &--chosen {
      cursor: grabbing;
      background: #e8edf7;
      border-radius: 8px;
    }

    &-cell {
      width: 100%
    }

    &-btn {
      cursor: pointer;
    }

    &-burger {
      opacity: 0.16;
    }
  }

  &__footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 24px 32px 32px;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    background-color: var(--rir-amelie);
  }
}

::v-deep {

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    border: 0;
    color: rgba(#E4EDFB, 0.48);
    background: rgba(#E4EDFB, 0.48);
    background-clip: padding-box;
    box-shadow: inset 0 0 0 10px;
    cursor: grab;
  }
}

.burger-column {
  flex-basis: 92px;
}

.actions-column {
  flex-basis: 112px;
}
</style>
