<template>
  <div class="form-department-modal">
    <h2 class="nomira mb-8 px-8 camembert">
      {{ title }}
    </h2>
    <section class="d-flex flex-direction-column px-8">
      <r-input
        class="mb-6"
        label="Название департамента"
        v-model="model.name"
        :error="$v.model.name.$error"
        :error-message="mesErr($v.model.name)"
      />
      <r-input
        class="mb-6"
        :error="$v.model.fullName.$error"
        :error-message="mesErr($v.model.fullName)"
        label="Полное название департамента"
        v-model="model.fullName"
      />
      <r-textarea
        label="Описание"
        :rows="6"
        v-model="model.description"
      />
      <r-button
        @click="save"
        class="mt-8"
        width="wide"
        :title="button"
      />
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import Department from '@/structure/entity/Department';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { required } from '@/plugins/vuelidate/customValidate';

export default {
  name: 'FormDepartmentModal',
  mixins: [validationMixin, validateMixin],
  props: {
    item: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'Сохранить'
    }
  },
  data: vm => ({
    model: new Department(vm.item)
  }),
  validations: {
    model: {
      name: {
        required: required('Обязательное поле')
      },
      fullName: {
        required: required('Обязательное поле')
      }
    }
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$rir.modal.close({ ...this.model.getApiData, ...this.model });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-department-modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;

  &__wrapper {
    max-width: 800px;
    margin: auto;
  }
}
</style>
