<template>
  <div class="remove-submit-modal">
    <remove-submit
      title="Удалить метку?"
      :subtitle="`Метка «${item.name}» будет удалена, а обращения, в которых она использовалась, останутся в системе`"
      :sending="sending"
      @submit="remove"
      @cancel="$rir.modal.close(null, true)"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import RemoveSubmit from '@/components/RemoveSubmit';

export default {
  name: 'RemoveSubmitModal',
  components: {
    RemoveSubmit
  },
  mixins: [validationMixin, validateMixin],
  props: {
    item: {
      type: Object,
      require: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sending: false
    };
  },
  methods: {
    ...mapActions('tags', ['removeTag']),
    async remove() {
      this.sending = true;
      await this.removeTag(this.item)
        .then(() => {
          this.$rir.notification.send({
            title: 'Метка удалена',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
        })
        .finally(() => {
          this.sending = false;
          this.$rir.modal.close(); (null, true);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-submit-modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}
</style>
