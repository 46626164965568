<template>
  <section class="toolbar">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'toolbar'
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>
