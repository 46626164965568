<template>
  <div class="form-tag-modal">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mt-8"
    >
      <r-spinner />
    </div>
    <div
      v-else
      class="form-tag-modal__wrapper"
    >
      <h2 class="nomira mb-4 camembert">
        {{ title || model.name }}
      </h2>
      <!-- <div class="mb-8">
        В метках разрешены только буквенно-цифровые латинские и кириллические символы, дефис. Без пробела.
      </div>-->
      <section class="d-flex align-items-center">
        <div class="flex-1 d-flex align-items-center">
          <color-picker
            v-model="model.color"
            :error="$v.model.color.$error"
          />
          <r-input
            class="flex-1 ml-8 mt-0"
            label="Наименование"
            v-model="model.name"
            :error="$v.model.name.$error"
            :error-message="mesErr($v.model.name)"
          />
        </div>
        <r-select
          class="flex-1 ml-8 mt-0"
          label="Категория метки"
          id-value="id"
          title-value="name"
          :items="tagCategories.list"
          v-model="model.tagCategory"
          return-object
          :error="$v.model.tagCategory.$error"
          :error-message="mesErr($v.model.tagCategory)"
        />
      </section>
      <r-button
        @click="save"
        class="mt-8"
        :loading="saving"
        :disabled="saving"
        :title="button"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapState, mapActions } from 'vuex';
import Tag from '@/structure/entity/Tag';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { required } from '@/plugins/vuelidate/customValidate';
import ColorPicker from '@/components/ColorPicker';

export default {
  name: 'FormTagModal',
  components: {
    ColorPicker
  },
  mixins: [validationMixin, validateMixin],
  props: {
    item: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'Сохранить'
    }
  },
  data: vm => ({
    model: new Tag(vm.item),
    isLoading: false,
    saving: false
  }),
  validations: {
    model: {
      color: {
        required: required('')
      },
      name: {
        required: required('Обязательное поле')
      },
      tagCategory: {
        required: required('Обязательное поле')
      }
    }
  },
  computed: {
    ...mapState('tagCategories', ['tagCategories'])
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    ...mapActions('tagCategories', ['getTagCategoriesList']),
    ...mapActions('tags', ['addTag', 'changeTag']),
    async getCategories() {
      this.isLoading = true;
      await this.getTagCategoriesList();
      this.isLoading = false;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      await this.item.id ? this.change() : this.add();
    },
    async add() {
      this.saving = true;
      await this.addTag(this.model)
        .then(() => {
          this.$rir.notification.send({
            title: 'Добавлена новая метка',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
          this.$rir.modal.close();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async change() {
      this.saving = true;
      await this.changeTag(this.model)
        .then(() => {
          this.$rir.notification.send({
            title: 'Тег изменен',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
          this.$rir.modal.close();
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>

.flex-1 {
  flex: 1
}
.form-tag-modal {
  display: flex;
  align-items: center;
  height: 100%;
  &__wrapper {
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  &::v-deep {
    .rir-form-item__content.error .color-picker__circle {
      border: 8px solid rgba(238,88,126,.08);
    }
  }
}

</style>
