import moment from 'moment'
moment.locale('ru')
export default {
  install: function (Vue) {
    Vue.filter('dateTextMonthYear', (date) => date ? moment(date).format('DD MMMM YYYY') : '')
    Vue.filter('dateMonthYear', (date) => date ? moment(date).format('DD.MM.YYYY') : '')
    Vue.filter('dateTextMonth', (date) => date ? moment(date).format('DD MMMM') : '')
    Vue.filter('dateTime', (date) => date ? moment(date).format('DD MMMM HH:mm') : '')
    Vue.filter('fullDateTime', (date) => date ? moment(date).format('DD.MM.YYYY, HH:mm') : '')
    Vue.filter('time', (date) => date ? moment(date).format('HH:mm') : '')
    Vue.filter('dateDiff', (date) => {
      if (date) {
        const diff = moment().diff(date, 'days')
        if (!diff) {
          return `сегодня, ${moment().format('HH:mm')}`
        }
        return `${moment().format('YYYY-MM-DD')}, ${moment().format('HH:mm')}`
      }
      return 'Неизвестно'
    })
  }
}
