<template>
  <div
    class="layout-main"
    :class="{ 'layout-main--close': !isSidebarOpen }"
  >
    <r-layout-dashboard
      :is-compact="true"
      :menu-options="{
        items: menuItems,
      }"
    >
      <template #header>
        <span
          class="taleggio"
        >
          Рабочее место <br> администратора
        </span>
      </template>
      <template #navigation>
        <toolbar
          id="toolbar"
          class="layout-main__toolbar mb-5"
        >
          <router-view name="toolbar" />
        </toolbar>
      </template>
      <template #footer>
        <div
          class="sidebar__logo-svg black"
        />
      </template>
      <router-view slot="content" class="layout-main__content" />
    </r-layout-dashboard>
    <!--   <rir-app-layout
       is-toggle
       @toggleMenu="toggleSidebarHandler"
       scrollable
     >
       <template #sidebar>
         <sidebar :is-sidebar-open="isSidebarOpen" />
       </template>
       <template #toolbar>
         <toolbar
           id="toolbar"
           class="layout-main__toolbar"
         >
           <router-view name="toolbar" />
         </toolbar>
       </template>
       <router-view slot="default" />
     </rir-app-layout>-->
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Sidebar from '@/layout/components/Sidebar';
import Toolbar from '@/layout/components/Toolbar';
import getEnv from '@/plugins/env';

export default {
  name: 'DefaultLayout',
  components: { Toolbar, Sidebar },
  data: () => ({
    version: `Версия: ${getEnv('VUE_APP_REV')}`,
    showSummary: parseInt(getEnv('VUE_APP_SHOW_SUMMARY'))

  }),
  computed: {
    ...mapState('apps', ['isSidebarOpen']),
    ...mapState('directories', ['directories', 'directory']),
    menuItems() {
      const list = [
        {
          title: 'Справочники',
          icon: 'list',
          children: this.directories
        },
        {
          title: 'Исполнители',
          icon: 'avatar',
          router: {
            path: '/users-bot'
          }
        }
      ];
      if (this.showSummary) {
        list.push({
          title: 'Сводка',
          icon: 'chart',
          router: {
            path: '/dashboard'
          }
        });
      }
      return list;
    }
  },

  methods: {
    ...mapMutations('apps', ['toggleSidebar']),
    toggleSidebarHandler() {
      this.toggleSidebar();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .RLayoutDashboard__navigation{
  height: auto!important;
}
.layout-main {
  &__content {
    padding: 0 12px;
  }
  
  &::v-deep {
    .rir-app-layout__body {
      left: 344px;
      width: calc(100% - 344px);
    }

    .rir-app-layout__body::after {
      display: none;
    }

    .rir-app-layout__body::before {
      left: 344px;
    }

    .rir-app-layout__sidebar {
      width: 344px;
      padding-right: 0;
      transition: 0.3s ease;
    }

    .rir-app-layout__close {
      position: fixed;
      left: 307px;

      &--open {
        left: 25px;
      }
    }

    .rir-app-layout__toolbar {
      height: auto;
      padding: 32px 32px 24px;
    }
  }

  &--close {
    &::v-deep {
      .rir-app-layout__sidebar {
        z-index: 9;
        width: 60px;
        padding-right: 0;
      }

      .rir-app-layout__body {
        left: 60px;
        width: calc(100% - 60px);
      }

      .rir-app-layout__body::before {
        left: 60px;
      }
    }
  }
}

</style>
