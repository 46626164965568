import ListEntities from '@/structure/case/ListEntities';
import Keyword from '@/structure/entity/Keyword';

export default {
  getKeywordsList({ commit, rootGetters }, payload) {
    commit('setProperty', { name: 'getting', value: true });
    return rootGetters.GET('ui/keyword', payload).then(data => {
      const list = new ListEntities({
        list: data,
        entity: Keyword
      });
      commit('setProperty', { name: 'keywords', value: list });
    }).finally(() => {
      commit('setProperty', { name: 'getting', value: false });
    });
  },
  addKeyword({ commit, rootGetters }, payload) {
    return rootGetters.POST('ui/keyword', payload);
  },
  editKeyword({ commit, rootGetters }, payload) {
    const { id } = payload;
    return rootGetters.PUT(`ui/keyword/${id}`, payload);
  },
  removeKeyword({ commit, rootGetters }, payload) {
    const { id } = payload;
    return rootGetters.DELETE(`ui/keyword/${id}`);
  }
};
