<template>
  <section class="sidebar">
    <div class="sidebar__title">
      <span
        v-if="isSidebarOpen"
        class="taleggio ameile--text"
      >
        Рабочее место <br> администратора
      </span>
    </div>
    <div
      class="sidebar__menu"
      ref="menu"
    >
      <router-link
        v-for="item in menuItems"
        :key="item.title"
        active-class="active"
        :to="{ name: item.route }"
        class="sidebar__menu-item"
        :class="{ 'sidebar__menu-item--small': !isSidebarOpen }"
      >
        <div v-if="isSidebarOpen">
          <r-icon
            fill="rocky"
            size="16"
            :icon="item.icon"
          />
          <span class="sulguni">{{ item.title }}</span>
        </div>
        <r-icon
          fill="rocky"
          size="16"
          v-else
          class="sidebar__menu-item-icon"
          :icon="item.icon"
        />
      </router-link>
    </div>
    <div class="sidebar__logo">
      <div
        v-if="isSidebarOpen"
        class="sidebar__logo-svg"
      />
    </div>
  </section>
</template>

<script>
import getEnv from '@/plugins/env';

export default {
  name: 'Sidebar',
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    version: `Версия: ${getEnv('VUE_APP_REV')}`,
    showSummary: parseInt(getEnv('VUE_APP_SHOW_SUMMARY')),
    menuItems: [
      {
        title: 'Справочники',
        icon: 'list',
        route: 'directories'
      },
      {
        title: 'Исполнители',
        icon: 'avatar',
        route: 'users-bot'
      }
    ]
  }),
  mounted() {
    if (this.showSummary) {
      this.menuItems.push({
        title: 'Сводка',
        icon: 'chart',
        route: 'dashboard'
      });
    }
  }
};
</script>
