<template>
  <div class="department">
    <div class="department__wrapper">
      <div class="department__header">
        <div class="department__title mb-6">
          <h2 class="mb-1 camembert">
            {{ department.name }}
          </h2>
          <span class="anie">
            <r-shorter
              :text="department.description || ''"
              :count-row="5"
              :tooltip-options="{
                position: 'center-left',
                alignTitle: 'start',
                maxWidth: '300px',
              }"
            />
          </span>
        </div>
        <r-button-action
          class="mb-6"
          title="Добавить тип проблемы"
          icon="add"
          @click.native="openAddModal"
        />
      </div>
      <table-component
        :is-search="true"
        search-field="name"
        :saving="saving"
        :columns="columns"
        :loading="isLoadPage"
        :list="problemsList"
        empty-details="Вы можете добавить новый тип проблемы"
        @edit="editForm"
        @delete="deleteItem"
        @save="saveProblems"
        draggable
      >
        <template #rowData="{ item, column }">
          <div
            v-if="column.key === 'description'"
            class="table-item"
          >
            <r-shorter
              :text="item[column.key] || ''"
              :count-row="3"
              :tooltip-options="{
                position: 'center-left',
                alignTitle: 'start',
                maxWidth: '300px',
              }"
            />
          </div>
          <div
            v-else
            class="table-item"
          >
            {{ item[column.key] }}
          </div>
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TableComponent from '@/components/TableComponent';
import Problem from '@/structure/entity/Problem';
import FormProblemModal from './components/FormProblemModal';

export default {
  name: 'DepartmentView',
  components: { TableComponent },
  data() {
    return {
      isLoadPage: false,
      columns: [
        {
          title: 'Тип проблемы',
          key: 'name',
          font: 'bryndza',
          width: 1
        },
        {
          title: 'Описание',
          key: 'description',
          font: 'mozzarella',
          width: 2
        }
      ],
      search: '',
      saving: false,
      department: {}
    };
  },
  computed: {
    ...mapState('problems', ['problems']),
    problemsList() {
      return this.problems?.list || [];
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    ...mapActions('problems', ['getProblemsList', 'changeProblemsList']),
    ...mapActions('departments', ['getDepartment']),
    async loadData() {
      this.isLoadPage = true;
      this.department = await this.getDepartment({
        id: this.$route.params.id
      });
      await this.getProblemsList({ departmentId: this.$route.params.id });
      this.isLoadPage = false;
    },
    getProblems(search) {
      this.search = search;
    },
    async editForm(item) {
      const res = await this.$rir.modal.open(FormProblemModal, {
        item,
        title: item.name,
        button: 'Редактировать'
      });
      if (typeof res.id !== 'undefined') item.updateData(res);
    },
    deleteItem(item) {
      this.problems.removeListEntity(item);
    },
    async openAddModal() {
      const res = await this.$rir.modal.open(FormProblemModal, {
        item: new Problem(),
        title: 'Новый тип проблемы',
        button: 'Добавить'
      });
      if (typeof res.id !== 'undefined') this.problems.addListEntity(res);
    },
    async saveProblems() {
      this.saving = true;
      await this.changeProblemsList({
        id: this.$route.params.id,
        data: this.problemsList
      })
        .then(() => {
          this.$rir.notification.send({
            title: 'Список типов проблем успешно сохранен!',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
        });
      this.saving = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.department {
  &__report {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  &__controls {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__control {
    flex: 1;
  }

  &__header {
    //padding: 0 32px;
  }

  &__action-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    span {
      color: var(--rir-rocky)
    }

    &:hover {
      &::v-deep {
        & path {
          fill: var(--rir-rocky--hover);
        }
      }

      span {
        color: var(--rir-rocky--hover);
      }
    }
  }

  &__reaction-time {
    padding-right: 16px;
  }
}
</style>
