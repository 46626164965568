import Entity from '@/structure/entity/Entity'

export default class Tag extends Entity {
  areaCode = null
  tagsCount = null
  updatedAt = null

  constructor (data = {}) {
    super()
    data && this.updateData(data)
  }
}
