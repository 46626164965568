import apps from './apps'
import departments from './departments'
import problems from './problems'
import session from './session'
import executors from './executors'
import directories from './directories'
import keywords from './keywords'
import tagCategories from './tagCategories'
import tags from './tags'

export default {
  apps,
  departments,
  problems,
  session,
  executors,
  directories,
  keywords,
  tagCategories,
  tags
}
