<template>
  <div
    id="app"
    class="app"
  >
    <r-modal ref="modal" />
    <r-notification ref="notification" />
    <component :is="$route.meta.layout" />
  </div>
</template>
<script>

export default {
  name: 'App',
  mounted() {
    this.$root.$modal = this.$refs.modal;
    this.$root.$notification = this.$refs.notification.pushNotification;
  }
};
</script>
<style lang="scss" scoped>
.app {
  height: 100%;

  &::v-deep {
    .rir-modal__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 32px 32px;
    }
  }
}

</style>
