<template>
  <section>
    <r-row class="mb-6">
      <r-col
        :cols="{
          widest: 6, wide: 6, middle: 6, narrow: 6,
        }"
      >
        <data-block
          :loading="isLoading"
        >
          <template slot="title">
            Прикладные приложения
          </template>
          <template slot="first-name">
            Всего
          </template>
          <template slot="first-data">
            {{ dataDashboard.apps.total }}
          </template>
          <template slot="secondary-name">
            Работают
          </template>
          <template slot="secondary-data">
            {{ dataDashboard.apps.active }}
          </template>
          <template slot="three-name">
            Ведутся тех. работы
          </template>
          <template slot="three-data">
            {{ dataDashboard.apps.suspended }}
          </template>
        </data-block>
      </r-col>
      <r-col
        :cols="{
          widest: 6, wide: 6, middle: 6, narrow: 6,
        }"
      >
        <data-block
          :loading="isLoading"
        >
          <template slot="title">
            Пользователи
          </template>
          <template slot="first-name">
            Всего
          </template>
          <template slot="first-data">
            {{ dataDashboard.users.total }}
          </template>
          <template slot="secondary-name">
            Активно сейчас
          </template>
          <template slot="secondary-data">
            {{ dataDashboard.users.active }}
          </template>
          <template slot="three-name">
            Кол. пользователей приложений
          </template>
          <template slot="three-data">
            {{ dataDashboard.users.appsTotal }}
          </template>
        </data-block>
      </r-col>
    </r-row>
    <r-row class="mb-6">
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.socialMedia.appName"
          :description="dataDashboard.socialMedia.departmentName"
          :icon="dataDashboard.socialMedia.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.socialMedia.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.socialMedia.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.socialMedia.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Открытых процессов
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.socialMedia.activeProcesses }}
          </template>
          <template slot="bottom-right-name">
            Завершенных процессов
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.socialMedia.completedProcesses }}
          </template>
        </apps-block>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.landRedistribution.appName"
          :description="dataDashboard.landRedistribution.departmentName"
          :icon="dataDashboard.landRedistribution.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.landRedistribution.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.landRedistribution.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.landRedistribution.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Открытых процессов
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.landRedistribution.activeProcesses }}
          </template>
          <template slot="bottom-right-name">
            Завершенных процессов
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.landRedistribution.completedProcesses }}
          </template>
        </apps-block>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.landProvision.appName"
          :description="dataDashboard.landProvision.departmentName"
          :icon="dataDashboard.landProvision.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.landProvision.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.landProvision.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.landProvision.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Открытых процессов
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.landProvision.activeProcesses }}
          </template>
          <template slot="bottom-right-name">
            Завершенных процессов
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.landProvision.completedProcesses }}
          </template>
        </apps-block>
      </r-col>
    </r-row>

    <r-row class="mb-6">
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.violationPrevention.appName"
          :description="dataDashboard.violationPrevention.departmentName"
          :icon="dataDashboard.violationPrevention.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.violationPrevention.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.violationPrevention.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.violationPrevention.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Открытых процессов
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.violationPrevention.activeProcesses }}
          </template>
          <template slot="bottom-right-name">
            Завершенных процессов
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.violationPrevention.completedProcesses }}
          </template>
        </apps-block>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.violationRci.appName"
          :description="dataDashboard.violationRci.departmentName"
          :icon="dataDashboard.violationRci.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.violationRci.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.violationRci.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.violationRci.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Открытых процессов
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.violationRci.activeProcesses }}
          </template>
          <template slot="bottom-right-name">
            Завершенных процессов
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.violationRci.completedProcesses }}
          </template>
        </apps-block>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.hr.appName"
          :description="dataDashboard.hr.departmentName"
          :icon="dataDashboard.hr.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.hr.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.hr.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.hr.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Открытых процессов
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.hr.activeProcesses }}
          </template>
          <template slot="bottom-right-name">
            Завершенных процессов
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.hr.completedProcesses }}
          </template>
        </apps-block>
      </r-col>
    </r-row>

    <r-row class="mb-6">
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.attestation.appName"
          :description="dataDashboard.attestation.departmentName"
          :icon="dataDashboard.attestation.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.attestation.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.attestation.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.attestation.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Назначено аттестаций
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.attestation.plannedAttestations }}
          </template>
          <template slot="bottom-right-name">
            Пройдено аттестаций
          </template>
          <template slot="bottom-right-data">
            {{ dataDashboard.attestation.completedAttestations }}
          </template>
        </apps-block>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.emergencyAlert.appName"
          :description="dataDashboard.emergencyAlert.departmentName"
          :icon="dataDashboard.emergencyAlert.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.emergencyAlert.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.emergencyAlert.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.emergencyAlert.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Контролируемые показатели
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.emergencyAlert.monitoredIndicators }}
          </template>
        </apps-block>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 6, narrow: 12,
        }"
      >
        <apps-block
          :loading="isLoading"
          :title="dataDashboard.dgh.appName"
          :description="dataDashboard.dgh.departmentName"
          :icon="dataDashboard.dgh.iconName"
        >
          <template slot="top-left-title">
            Всего пользователей
          </template>
          <template slot="top-left-data">
            {{ dataDashboard.dgh.usersTotal }}
          </template>
          <template slot="top-right-title">
            Активных пользователей
          </template>
          <template slot="top-right-data">
            {{ dataDashboard.dgh.usersActive }}
          </template>
          <template slot="center-name">
            Последнее действие
          </template>
          <template slot="center-data">
            {{ dataDashboard.dgh.userLastAccessTime | dateDiff }}
          </template>
          <template slot="bottom-left-name">
            Контролируемые показатели
          </template>
          <template slot="bottom-left-data">
            {{ dataDashboard.dgh.monitoredIndicators }}
          </template>
        </apps-block>
      </r-col>
    </r-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import DataBlock from './components/DataBlock';
import AppsBlock from './components/AppsBlock';

export default {
  name: 'Dashboard',
  components: { AppsBlock, DataBlock },
  data: () => ({
    isLoading: true,
    dataDashboard: {
      apps: { total: 9, active: 9, suspended: 0 },
      attestation: {
        iconName: 'attestation',
        appName: 'Аттестатция',
        departmentName: 'HR',
        usersTotal: 15,
        usersActive: 2,
        userLastAccessTime: 0,
        plannedAttestations: 32,
        completedAttestations: 102
      },
      dgh: {
        iconName: 'dgh',
        appName: 'Формирование отчётности',
        departmentName: 'ДГХ',
        usersTotal: 9,
        usersActive: 3,
        userLastAccessTime: 0,
        monitoredIndicators: 17
      },
      emergencyAlert: {
        iconName: 'm4s',
        appName: 'Оперативная сводка',
        departmentName: 'ГОиЧС',
        usersTotal: 14,
        usersActive: 5,
        userLastAccessTime: 0,
        monitoredIndicators: 14
      },
      hr: {
        iconName: 'hr-social',
        appName: 'Обращения сотрудников',
        departmentName: 'HR',
        usersTotal: 3,
        usersActive: 0,
        userLastAccessTime: 0,
        activeProcesses: 4,
        completedProcesses: 0
      },
      landProvision: {
        iconName: 'land-locate_buildings',
        appName: 'Участки со строением',
        departmentName: 'ДУМИ',
        usersTotal: 9,
        usersActive: 0,
        userLastAccessTime: 0,
        activeProcesses: 0,
        completedProcesses: 0
      },
      landRedistribution: {
        iconName: 'area',
        appName: 'Перераспределение земель',
        departmentName: 'ДУМИ',
        usersTotal: 9,
        usersActive: 0,
        userLastAccessTime: 0,
        activeProcesses: 5,
        completedProcesses: 0
      },
      socialMedia: {
        iconName: 'arm',
        appName: 'Социальные сети',
        departmentName: 'АРГЮС',
        usersTotal: 3,
        usersActive: 1,
        userLastAccessTime: 1612876647000,
        activeProcesses: 147,
        completedProcesses: 29
      },
      users: { total: 236, active: 5, appsTotal: 74 },
      violationPrevention: {
        iconName: 'road-prevention',
        appName: 'Профилактика',
        departmentName: 'ДОБиК',
        usersTotal: 5,
        usersActive: 4,
        userLastAccessTime: 1612876470000,
        activeProcesses: 34,
        completedProcesses: 34
      },
      violationRci: {
        iconName: 'road-control',
        appName: 'Дорожный контроль',
        departmentName: 'ДОБиК',
        usersTotal: 7,
        usersActive: 1,
        userLastAccessTime: 1612876470000,
        activeProcesses: 27,
        completedProcesses: 6
      }
    }
  }),
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters(['GET'])
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.dataDashboard = await this.GET('common/dashboard');
      this.isLoading = false;
    }
  }
};
</script>
