<template>
  <div
    class="d-flex flex-direction-column justify-content-center align-items-center"
    style="position: relative; height: 100%; width: 100%;"
  >
    <div
      class="d-flex flex-direction-column pa-8"
      style="max-width: 550px;"
    >
      <r-icon
        class="mb-6"
        icon="warning"
        fill="fargo"
        :size="56"
      />
      <span
        class="mb-8 titanic--text"
        style="font-weight: bold; font-size: 32px;"
      >
        {{ title }}
      </span>
      <div
        class="mb-8"
        v-html="subtitle"
      />
      <div class="d-flex justify-space-between">
        <r-button
          style="width: 232px;"
          @click="$emit('cancel')"
          type="secondary"
          width="wide"
          title="Не удалять"
          class="mr-6"
        />
        <r-button
          style="width: 232px;"
          @click="$emit('submit')"
          :loading="sending"
          :disabled="sending"
          title="Удалить"
          width="wide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import validateMixin from '@/plugins/vuelidate/validateMixin';

export default {
  name: 'RemoveSubmit',
  mixins: [validationMixin, validateMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    sending: {
      type: Boolean,
      default: false
    }
  }
};
</script>
