import ListEntities from '@/structure/case/ListEntities';
import Department from '@/structure/entity/Department';

export default {
  getDepartmentsList({ commit, rootGetters }) {
    return rootGetters.GET('ui/department').then(data => {
      const list = new ListEntities({
        list: data,
        entity: Department
      });
      commit('setProperty', {
        name: 'departments',
        value: list
      });
    });
  },
  changeDepartamentsList({ commit, rootGetters }, payload) {
    return rootGetters.POST('ui/department', payload);
  },
  getDepartment({ commit, rootGetters }, payload) {
    const { id } = payload;
    return rootGetters.GET(`ui/department/${id}`);
  }
};
