import getEnv from '@/plugins/env';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const showKeywords = parseInt(getEnv('VUE_APP_SHOW_KEYWORDS'));
const showTags = parseInt(getEnv('VUE_APP_SHOW_TAGS'));

export const InitState = () => ({
  directories: [
    {
      id: 'departaments',
      title: 'Департаменты',
      router: {
        path: '/departaments'
      },
      isActive: true
    },
    {
      id: 'problems',
      title: 'Типы проблем',
      router: {
        path: '/problems'
      },
      isActive: true
    },
    {
      id: 'keywords',
      title: 'Ключевые слова',
      router: {
        path: '/keywords'
      },
      isActive: showKeywords
    },
    {
      id: 'tags',
      title: 'Метки',
      router: {
        path: '/tags'
      },
      isActive: showTags
    },
    {
      id: 'tag-categories',
      title: 'Категории меток',
      router: {
        path: '/tag-categories'
      },
      isActive: showTags
    }
  ].filter(item => item.isActive),
  directory: {
    id: 'departaments',
    name: 'Департаменты',
    isActive: true
  }
});

export default {
  namespaced: true,
  state: InitState,
  actions,
  mutations,
  getters
};
