<template>
  <div class="problems">
    <div
      v-if="isLoadPage"
      class="d-flex justify-content-center"
    >
      <r-spinner />
    </div>
    <template v-else>
      <r-input
        label="Поиск"
        before-icon="search"
        size="eluno"
        :is-clear-model-string="true"
        class="mb-6"
        v-model="searchText"
      />
      <div
        class="problems__departments-list"
      >
        <div
          v-for="department in departamentsList"
          :key="department.id"
          class="problems__department"
          @click="goToProblems(department.id)"
        >
          <h5 class="clamp-2 taleggio">
            {{ department.name }}
          </h5>
          <span class="feta anie">
            {{ getProblemCountText(department.problemCount) }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { declinations } from '@/utils';

export default {
  name: 'Problems',
  data() {
    return {
      isLoadPage: false,
      searchText: ''
    };
  },
  computed: {
    ...mapState('departments', ['departments']),
    departamentsList() {
      let list = this.departments?.list;
      if (this.searchText) {
        list = list.filter(
          el => this.wordMatch(el.name || '', this.searchText) <= 0.5
        );
      }
      return list;
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions('departments', ['getDepartmentsList']),
    async loadData() {
      this.isLoadPage = true;
      await this.getDepartmentsList();
      this.isLoadPage = false;
    },
    goToProblems(id) {
      this.$router.push(`/problems/department/${id}`);
    },
    getProblemCountText(num) {
      return `${num || 'нет'} ${declinations(num, ['тип', 'типа', 'типов'])} проблем`;
    },
    wordMatch(left, right) {
      // 0 - полное совпадение
      // 1 - нет совпадений
      left = left?.toLowerCase();
      right = right?.toLowerCase();

      if (left.includes(right)) {
        return 0;
      }

      left = `\b\b${left.toLowerCase()}\f\f`;
      right = `\b\b${right.toLowerCase()}\f\f`;

      let dist = -4;

      for (let i = 0; i < left.length - 2; ++i) {
        if (!right.includes(left.slice(i, i + 3))) ++dist;
      }

      for (let i = 0; i < right.length - 2; ++i) {
        if (!left.includes(right.slice(i, i + 3))) ++dist;
      }

      return Math.max(0, dist) / (left.length + right.length - 8);
    }
  }
};
</script>

<style lang="scss" scoped>
.problems {
  //padding: 0 32px;
  &__departments-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }

  &__department {
    padding: 20px;
    background: var(--rir-amelie);
    border-radius: 16px;
    height: 124px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      box-shadow: 0 8px 32px 0 rgba(12, 16, 24, 0.12);
    }
  }
}
</style>
