import Vue from 'vue';
import App from './App.vue';
import './plugins/axios';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/scss/main.scss';
import RirLib from './plugins/RirLib';
import Vuelidate from 'vuelidate';
import filterDate from '@/plugins/filters/filter';
import clickOutside from './plugins/ui/clickOutside';

import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import getEnv from '@/plugins/env';

Vue.config.productionTip = false;
Vue.use(filterDate);
Vue.use(clickOutside);

const initKeyCloak = () => {
  return new Promise(resolve => {
    Vue.use(VueKeyCloak, {
      init: {
        onLoad: 'login-required',
        checkLoginIframe: false
      },
      config: {
        authRealm: getEnv('VUE_APP_KC_REALM'),
        authUrl: getEnv('VUE_APP_KC_AUTH'),
        authClientId: getEnv('VUE_APP_KC_CLIENT')
      },
      onReady: async (keycloak) => {
        Vue.use(Vuelidate)
        await store.dispatch('session/getUserInfo')
        const role = store.getters['session/rolePlatform']
        !role ? keycloak.logout() : resolve()
      }
    })
  })
}
export default new Vue({
  data: () => ({
    windowWidth: window.innerWidth,
    initSession: false
  }),
  async mounted () {
    await initKeyCloak()
    this.initSession = true
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  router,
  store,
  $rir: RirLib,
  render (h) {
    if (this.initSession) {
      return h(App)
    }
  }
}).$mount('#app')
