import ListEntities from '@/structure/case/ListEntities'
import Tag from '@/structure/entity/Tag'

export default {
  getTagsList ({ commit, rootGetters }, payload) {
    return rootGetters.GET('ui/appeal-tag', payload).then(data => {
      const list = new ListEntities({
        list: data,
        entity: Tag
      })
      commit('setProperty', { name: 'tags', value: list })
    })
  },
  addTag ({ commit, rootGetters }, payload) {
    return rootGetters.POST('ui/appeal-tag', payload)
  },
  changeTag ({ commit, rootGetters }, payload) {
    return rootGetters.PUT(`ui/appeal-tag/${payload.id}`, payload)
  },
  removeTag ({ commit, rootGetters }, payload) {
    return rootGetters.DELETE(`ui/appeal-tag/${payload.id}`)
  }
}
