var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('section',{staticClass:"rocky--bg kurza pl-1 pr-1 pt-5 pb-5 card-apps__loading-data"},[_c('r-spinner',{attrs:{"size":"nelida","color":"white"}})],1):_c('section',{staticClass:"rocky--bg kurza pl-1 pr-1 pt-5 pb-5"},[_c('r-row',[_c('r-col',{staticClass:"amelie--text melia mb-5",attrs:{"cols":{ middle: 12 }}},[_vm._t("title")],2)],1),_c('r-row',[_c('r-col',{attrs:{"cols":{
        widest: 2, wide: 2, middle: 2, narrow: 12,
      }}},[_c('div',{staticClass:"amelie--text ekas kleodora mb-1"},[_vm._t("first-name")],2),_c('div',{staticClass:"amelie--text clymenti"},[_vm._t("first-data")],2)]),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 4, narrow: 12,
      }}},[_c('div',{staticClass:"amelie--text ekas kleodora mb-1"},[_vm._t("secondary-name")],2),_c('div',{staticClass:"amelie--text clymenti"},[_vm._t("secondary-data")],2)]),_c('r-col',{attrs:{"cols":{
        widest: 6, wide: 6, middle: 6, narrow: 12,
      }}},[_c('div',{staticClass:"amelie--text ekas kleodora mb-1"},[_vm._t("three-name")],2),_c('div',{staticClass:"amelie--text clymenti"},[_vm._t("three-data")],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }