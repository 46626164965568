<template>
  <div class="form-tag-categories-modal">
    <div class="form-tag-categories-modal__wrapper">
      <h2 class="nomira mb-8 camembert">
        {{ title || model.name }}
      </h2>
      <r-input
        label="Наименование"
        v-model="model.name"
        :error="$v.model.name.$error"
        :error-message="mesErr($v.model.name)"
      />
      <r-textarea
        class="mt-6"
        label="Описание"
        v-model="model.description"
        :rows="5"
      />
      <r-button
        class="mt-8"
        @click="save"
        :loading="saving"
        :disabled="saving"
        :title="button"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';
import TagCategory from '@/structure/entity/TagCategory';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { required } from '@/plugins/vuelidate/customValidate';

export default {
  name: 'FormTagCategoriesModal',
  mixins: [validationMixin, validateMixin],
  props: {
    item: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'Сохранить'
    }
  },
  data: vm => ({
    model: new TagCategory(vm.item),
    saving: false
  }),
  validations: {
    model: {
      name: {
        required: required('Обязательное поле')
      }
    }
  },
  methods: {
    ...mapActions('tagCategories', ['changeTagCategory', 'addTagCategory']),
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      await this.item.id ? this.change() : this.add();
    },
    async add() {
      this.saving = true;
      await this.addTagCategory(this.model)
        .then(() => {
          this.$rir.notification.send({
            title: 'Добавлена новая категория',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
          this.$rir.modal.close();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async change() {
      this.saving = true;
      await this.changeTagCategory(this.model)
        .then(() => {
          this.$rir.notification.send({
            title: 'Категория изменена',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
          this.$rir.modal.close();
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-tag-categories-modal {
  display: flex;
  align-items: center;
  height: 100%;
  &__wrapper {
    max-width: 800px;
    width: 100%;
    margin: auto;
  }
}
</style>
