<template>
  <section
    class="rocky--bg kurza pl-1 pr-1 pt-5 pb-5 card-apps__loading-data"
    v-if="loading"
  >
    <r-spinner
      size="nelida"
      color="white"
    />
  </section>
  <section
    v-else
    class="rocky--bg kurza pl-1 pr-1 pt-5 pb-5"
  >
    <r-row>
      <r-col
        :cols="{ middle: 12 }"
        class="amelie--text melia mb-5"
      >
        <slot name="title" />
      </r-col>
    </r-row>
    <r-row>
      <r-col
        :cols="{
          widest: 2, wide: 2, middle: 2, narrow: 12,
        }"
      >
        <div class="amelie--text ekas kleodora mb-1">
          <slot name="first-name" />
        </div>
        <div class="amelie--text clymenti">
          <slot name="first-data" />
        </div>
      </r-col>
      <r-col
        :cols="{
          widest: 4, wide: 4, middle: 4, narrow: 12,
        }"
      >
        <div class="amelie--text ekas kleodora mb-1">
          <slot name="secondary-name" />
        </div>
        <div class="amelie--text clymenti">
          <slot name="secondary-data" />
        </div>
      </r-col>
      <r-col
        :cols="{
          widest: 6, wide: 6, middle: 6, narrow: 12,
        }"
      >
        <div class="amelie--text ekas kleodora mb-1">
          <slot name="three-name" />
        </div>
        <div class="amelie--text clymenti">
          <slot name="three-data" />
        </div>
      </r-col>
    </r-row>
  </section>
</template>

<script>
export default {
  name: 'DataBlock',
  props: {
    loading: {
      type: Boolean
    }
  }
};
</script>
