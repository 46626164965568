import mixinsCommit from '../../mixins/mixinsCommit'
export default {
  ...mixinsCommit,
  changeActionbar (state, payload) {
    state.actionbar = payload
  },
  toggleSidebar (state) {
    state.isSidebarOpen = !state.isSidebarOpen
  }
}
