import ListEntities from '@/structure/case/ListEntities'
import TagCategory from '@/structure/entity/TagCategory'

export default {
  getTagCategoriesList ({ commit, rootGetters }, payload) {
    return rootGetters.GET('ui/tag-category', payload).then(data => {
      const list = new ListEntities({
        list: data,
        entity: TagCategory
      })
      commit('setProperty', { name: 'tagCategories', value: list })
    })
  },
  addTagCategory ({ commit, rootGetters }, payload) {
    return rootGetters.POST('ui/tag-category', payload)
  },
  changeTagCategory ({ commit, rootGetters }, payload) {
    return rootGetters.PUT(`ui/tag-category/${payload.id}`, payload)
  },
  removeTagCategory ({ commit, rootGetters }, payload) {
    return rootGetters.DELETE(`ui/tag-category/${payload.id}`)
  }
}
