<template>
  <section
    class="kurza white--bg pa-5 card-apps__loading"
    v-if="loading"
  >
    <r-spinner
      size="nelida"
    />
  </section>
  <section
    v-else
    class="kurza white--bg pa-5 card-apps"
  >
    <div class="mb-5">
      <div
        class="mb-3"
        v-if="icon"
      >
        <r-icon
          fill="rocky"
          :icon="icon"
          size="56"
        />
      </div>
      <div class="d-flex flex-direction-column">
        <span class="melia titanic--text mb-1">{{ title }}</span>
        <span class="metioche titanic--text anie">{{ description }}</span>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-5">
      <div class="d-flex flex-direction-column mr-8 card-apps__left">
        <span class="kleodora titanic--text mb-1 anie">
          <slot name="top-left-title" />
        </span>
        <span class="clymenti titanic--text">
          <slot name="top-left-data" />
        </span>
      </div>
      <div class="d-flex flex-direction-column flex-auto card-apps__right">
        <span class="kleodora titanic--text mb-1 anie">
          <slot name="top-right-title" />
        </span>
        <span class="clymenti titanic--text">
          <slot name="top-right-data" />
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center mb-5">
      <div class="d-flex flex-direction-column">
        <span class="kleodora titanic--text mb-1 anie">
          <slot name="center-name" />
        </span>
        <span class="clymenti titanic--text">
          <slot name="center-data" />
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex flex-direction-column mr-8 card-apps__left">
        <span class="kleodora titanic--text mb-1 anie">
          <slot name="bottom-left-name" />
        </span>
        <span class="clymenti titanic--text">
          <slot name="bottom-left-data" />
        </span>
      </div>
      <div class="d-flex flex-direction-column card-apps__right">
        <span class="kleodora titanic--text mb-1 anie">
          <slot name="bottom-right-name" />
        </span>
        <span class="clymenti titanic--text">
          <slot name="bottom-right-data" />
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppsBlock',
  props: {
    loading: {
      type: Boolean
    },
    icon: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  }
};
</script>
