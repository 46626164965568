<template>
  <div class="color-picker">
    <div
      class="color-picker__circle"
      :style="`background-color: ${value}`"
      :class="{ error: error }"
      @click="toggleColorPicker"
      v-inner-click-outside="closeColorPicker"
    >
      <transition>
        <sketch-picker
          v-if="isOpenColorPicker"
          class="color-picker__picker"
          :value="colors"
          @input="changedColor"
          :preset-colors="swatches"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'

export default {
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch
  },
  props: {
    value: {
      type: String,
      default: '#AB213A'
    },
    error: {
      type: Boolean,
      default: false, 
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpenColorPicker: false,
      colors: '#AB213A',
      swatches: [
        '#6D3AA6',
        '#9954F2',
        '#BB9AF4',
        '#DBCCFA',
        '#711426',
        '#AB213A',
        '#E14761',
        '#F7CFD5',
        '#113079',
        '#214EB0',
        '#3D75E4',
        '#81ABEE',
        '#643302',
        '#9E4F00',
        '#D06E0B',
        '#E9A35D',
        '#304D0A',
        '#467510',
        '#57A003',
        '#8DC95E',
        '#2E3648',
        '#495469',
        '#6F7A90',
        '#d9dce3'
      ]
    }
  },
  watch: {
    value (val) {
      this.colors = val
    }
  },
  methods: {
    changedColor (color) {
      this.$emit('input', color.hex)
    },
    toggleColorPicker () {
      this.isOpenColorPicker = !this.isOpenColorPicker
    },
    closeColorPicker() {
      this.isOpenColorPicker = false;
    },
  }
}
</script>

<style lang="scss" scoped>

.error {
  border-color: var(--rir-schindler) !important;
}
.color-picker {
  &__circle {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 8px solid #eff0f2;
    transition: border-color, .2s;
    cursor: pointer;

    &:hover {
      border-color: #e5e6e9;
    }
  }

  &__picker {
    position: absolute;
    top: 48px;
    right: 0;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  }

  &::v-deep {
    .vc-sketch {
      border-radius: 8px;
      padding: 20px;
    }

    .vc-sketch-saturation-wrap {
      border-radius: 8px;
    }

    .vc-saturation-circle {
      width: 16px;
      height: 16px;
      border: 2px solid #FFFFFF;
      box-shadow: none;
      transform: translate(-7px, -9px);
    }
    .vc-sketch-sliders {
      padding: 0;
      margin-top: 24px;
    }

    .vc-sketch-color-wrap,
    .vc-sketch-alpha-wrap,
    .vc-sketch-field {
      display: none;
    }

    .vc-sketch-hue-wrap {
      height: 16px;
    }

    .vc-sketch-sliders .vc-hue {
      border-radius: 8px;
    }

    .vc-hue-picker {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      border: 2px solid #FFFFFF;
      box-shadow: none;
      transform: translate(-7px, -1px);
      background-color: rgba(0, 0, 0, 0);
    }

    .vc-sketch-presets {
      border-top: none;
      padding-top: 0;
      margin-top: 24px;
    }

    .vc-sketch-presets-color {
      border-radius: 8px;
      box-shadow: none;
    }
  }
}
</style>
