<template>
  <div class="keywords">
    <r-button-action
      class="mb-6"
      title="Добавить"
      icon="add"
      @click.native="openAddModal"
    />
    <div class="keywords__controls mb-6">
      <!--<div class="keywords__addition">
        <r-input
          class="keywords__addition-input"
          label="Ключевое слово"
          v-model.lazy="$v.model.name.$model"
          :error="model.name ? $v.model.name.$error : false"
          :error-message="mesErr($v.model.name)"
        />
        <r-button-simple
          size="larishae"
          :disabled="!model.name"
          class="ml-2"
          icon="add"
          icon-size="20"
          @click="addNewKeyword"
        />
      </div>-->
      <r-input
        class="keywords__search"
        label="Поиск"
        v-model="search"
        before-icon="search"
        title-value="name"
        sub-title-value="description"
      /><!--  //:call-search="searchKeyword"-->
    </div>
    <p class="mozzarella anie mb-4">
      Наименование
    </p>
    <div
      v-if="keywordsList && keywordsList.length"
      class="keywords__list"
    >
      <div
        v-for="keyword in keywordsList"
        :key="keyword.id"
        class="keywords__item"
      >
        <span class="feta">{{ keyword.name }}</span>
        <div class="keywords__item-actions">
          <r-icon
            size="16"
            icon="edit"
            fill="rocky"
            @click.native="openEditModal(keyword)"
            class="mr-6"
          />
          <r-icon
            size="16"
            icon="delete"
            fill="fargo"
            @click.native="deleteKeyword(keyword)"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="getting"
      class="d-flex justify-content-center"
    >
      <r-spinner />
    </div>
    <empty
      v-else
      class="mt-10"
      title="Данные отсутствуют"
      details="Вы можете добавить новое ключевое слово"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import _ from 'lodash';
import Keyword from '@/structure/entity/Keyword';
import Empty from '@/components/Empty';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { regEx, required } from '@/plugins/vuelidate/customValidate';
import FormKeywordsModal from './components/FormKeywordModal';

export default {
  name: 'Keywords',
  components: { Empty },
  mixins: [validationMixin, validateMixin],
  data() {
    return {
      model: new Keyword(),
      search: null
    };
  },
  computed: {
    ...mapState('keywords', ['keywords', 'getting']),
    keywordsList() {
      return this.keywords?.list;
    }
  },
  validations: {
    model: {
      name: {
        required: required('Обязательное поле'),
        regEx: regEx(/^[\w\dа-яА-Я-]*$/, 'Без пробелов и спец. символов (разрешено только "-")')
      }
    }
  },
  watch: {
    search: _.debounce(function (newVal) {
      this.searchKeyword(newVal);
    }, 1000)
  },
  mounted() {
    this.getKeywordsList();
  },
  methods: {
    ...mapActions('keywords', ['getKeywordsList', 'addKeyword', 'removeKeyword']),
    async openAddModal() {
      await this.$root.$modal.openModal(FormKeywordsModal, {
        item: new Keyword(),
        title: 'Новое ключевое слово',
        button: 'Добавить',
        action: 'addKeyword'
      });
      this.getKeywordsList();
    },
    async openEditModal(item) {
      await this.$root.$modal.openModal(FormKeywordsModal, {
        item: new Keyword(item),
        title: 'Ключевое слово',
        button: 'Сохранить',
        action: 'editKeyword'
      });
      this.getKeywordsList();
    },
    // async addNewKeyword(item) {
    //   await this.addKeyword(item).then(() => {
    //     this.$rir.notification.send({
    //       title: 'Добавлено новое ключевое слово',
    //       seconds: 10,
    //       iconOptions: {
    //         fill: 'matrix',
    //         icon: 'warning'
    //       }
    //     });
    //   });
    //   this.model = new Keyword();
    //   this.getKeywordsList();
    // },
    async deleteKeyword(keyword) {
      await this.removeKeyword({ id: keyword.id });
      this.getKeywordsList();
    },
    async searchKeyword(search) {
      await this.getKeywordsList({ search });
      return this.keywordsList;
    }
  }
};
</script>

<style lang="scss" scoped>
.keywords {
  //padding: 0 32px;
  max-width: 50%;

  &__controls {
    display: flex;
    align-items: center;
  }

  &__addition {
    flex: 1;
    display: flex;

    &-input {
      flex: 1
    }
  }

  &__search {
    flex: 1
  }

  &__list {
    //width: calc(50% - 64px);
  }

  &__item {
    transition: .2s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--rir-arrival);
    padding: 25px 0;
    &-actions{
      transition: .2s ease-in-out;
      opacity: 0;
    }
    &:hover {
      .keywords__item-actions{
        opacity: 1;
      }
      background: var(--main-rush, #F6F9FE);
    }
  }
}
</style>
