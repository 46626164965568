<template>
  <div style="width: 100%">
    <r-bread-crumbs
      class="mb-2"
      :items="$route.meta.breadcrumbs || []"
    />
    <div class="toolbar-directories">
      <h2 class="nomira camembert">
        {{ $route.meta.title }}
      </h2>
      <user-info />
    </div>
  </div>
</template>

<script>
import UserInfo from '@/layout/components/UserInfo';

export default {
  name: 'ToolbarDirectories',
  components: { UserInfo },
};
</script>

<style lang="scss" scoped>
.toolbar-directories {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
