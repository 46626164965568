import Entity from '@/structure/entity/Entity'

export default class Department extends Entity {
  fullName = null
  problemCount = null

  constructor (data = {}) {
    super()
    data && this.updateData(data)
  }
}
