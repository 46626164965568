<template>
  <div class="tag-categories">
    <r-button-action
      class="tag-categories__action-button mb-6"
      title="Добавить"
      @click="openAddModal"
      icon="add"
    />
    <div class="tag-categories__search mb-8">
      <r-input
        v-model="searching"
        @input="debounceInput"
        label="Поиск"
      />
    </div>
    <table-component
      :saving="saving"
      :loading="isLoadPage"
      :columns="columns"
      empty-details="Вы можете добавить новую категорию"
      :list="tagCategoriesList"
      @edit="editForm"
      @delete="deleteItem"
      :save-button="false"
      :sort-mode="true"
      :sort-fields="{
        name: true,
        description: true,
        tagsCount: true,
        updatedAt: true,
      }"
    >
      <template #rowData="{ item, column }">
        <div
          v-if="column.key === 'name'"
          class="tag-categories__table-item"
        >
          <r-shorter
            :text="item[column.key] || '—'"
            :count-row="1"
            :tooltip-options="{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }"
          />
        </div>
        <div
          v-if="column.key === 'updatedAt'"
          class="tag-categories__table-item"
        >
          <p class="feta mb-1">
            {{ item.updatedAt | dateMonthYear }}
          </p>
          <p class="mozarella anie">
            {{ item.updatedAt | time }}
          </p>
        </div>
        <div
          v-else-if="column.key === 'description'"
          class="tag-categories__table-item"
        >
          <r-shorter
            :text="item[column.key] || '—'"
            :count-row="3"
            :tooltip-options="{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }"
          />
        </div>
        <div
          v-else
          class="tag-categories__table-item"
        >
          {{ item[column.key] }}
        </div>
      </template>
    </table-component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TableComponent from '@/components/TableComponent';
import TagCategory from '@/structure/entity/TagCategory';
import FormTagCategoriesModal from './components/FormTagCategoriesModal';
import RemoveSubmitModal from './components/RemoveSubmitModal';
import { debounce } from '@/plugins/lib';

export default {
  name: 'TagCategories',
  components: { TableComponent },
  data: () => ({
    isLoadPage: false,
    searching: null,
    columns: [
      {
        title: 'Наименование',
        key: 'name',
        font: 'bryndza',
        width: 2
      },
      {
        title: 'Описание',
        key: 'description',
        font: 'mozzarella',
        width: 3
      },
      {
        title: 'Кол-во меток',
        key: 'tagsCount',
        font: 'mozzarella',
        width: 1
      },
      {
        title: 'Изменено',
        key: 'updatedAt',
        font: 'mozzarella',
        width: 1
      }
    ],
    saving: false
  }),
  computed: {
    ...mapState('tagCategories', ['tagCategories']),
    tagCategoriesList() {
      return this.tagCategories?.list || [];
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions('tagCategories', ['getTagCategoriesList']),
    async loadData(payload) {
      this.isLoadPage = true;
      await this.getTagCategoriesList(payload);
      this.isLoadPage = false;
    },
    async openAddModal() {
      await this.$rir.modal.open(FormTagCategoriesModal, {
        item: new TagCategory(),
        title: 'Новая категория меток',
        button: 'Добавить'
      });
      this.loadData();
    },
    async editForm(item) {
      await this.$rir.modal.open(FormTagCategoriesModal, {
        item,
        button: 'Редактировать'
      });
      this.loadData();
    },
    async deleteItem(item) {
      await this.$rir.modal.open(RemoveSubmitModal, { item });
      this.loadData();
    },
    debounceInput: debounce(function () {
      this.loadData({ search: this.searching });
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>
.tag-categories {
  &__report {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  &__table {
    &-item {
      width: 100%
    }
  }

  &__search {
  //  padding: 0 32px;
  }

  &__action-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    //padding: 0 32px;

    span {
      color: var(--rir-rocky)
    }

    &:hover {
      &::v-deep {
        & path {
          fill: var(--rir-rocky--hover);
        }
      }

      span {
        color: var(--rir-rocky--hover);
      }
    }
  }

  &__reaction-time {
    padding-right: 16px;
  }
}
</style>
