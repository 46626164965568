import ListEntities from '@/structure/case/ListEntities'
import Problem from '@/structure/entity/Problem'

export default {
  getProblemsList ({ commit, rootGetters }, payload) {
    return rootGetters.GET('ui/problem', payload).then(data => {
      const list = new ListEntities({
        list: data,
        entity: Problem
      })
      commit('setProperty', { name: 'problems', value: list })
    })
  },
  changeProblemsList ({ commit, rootGetters }, payload) {
    const { id, data } = payload
    return rootGetters.POST(`ui/problem/department/${id}`, data)
  }
}
