<template>
  <div class="directories">
   <!-- <r-tabs
      class="mb-6 pl-8 pr-8"
      :value="directory"
      title-value="name"
      :items="directories"
      @input="changeTab"
    />-->
    <component :is="$route.name" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Departaments from '@/views/directories/Departments/Departaments';
import Keywords from '@/views/directories/Keywords/Keywords';
import Problems from '@/views/directories/Problems/Problems';
import Tags from '@/views/directories/Tags/Tags';
import TagCategories from '@/views/directories/TagCategories/TagCategories';

export default {
  name: 'Directories',
  components: {
    Departaments,
    Keywords,
    Problems,
    Tags,
    TagCategories
  },
  computed: {
    ...mapState('directories', ['directories', 'directory'])
  },
  mounted() {
    console.log(this.$route, this.$router)
  },
  methods: {
    ...mapMutations('directories', ['setProperty']),
    changeTab(tab) {
      this.setProperty({ name: 'directory', value: tab });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
