var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('r-row',{staticClass:"mb-6"},[_c('r-col',{attrs:{"cols":{
        widest: 6, wide: 6, middle: 6, narrow: 6,
      }}},[_c('data-block',{attrs:{"loading":_vm.isLoading}},[_c('template',{slot:"title"},[_vm._v(" Прикладные приложения ")]),_c('template',{slot:"first-name"},[_vm._v(" Всего ")]),_c('template',{slot:"first-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.apps.total)+" ")]),_c('template',{slot:"secondary-name"},[_vm._v(" Работают ")]),_c('template',{slot:"secondary-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.apps.active)+" ")]),_c('template',{slot:"three-name"},[_vm._v(" Ведутся тех. работы ")]),_c('template',{slot:"three-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.apps.suspended)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 6, wide: 6, middle: 6, narrow: 6,
      }}},[_c('data-block',{attrs:{"loading":_vm.isLoading}},[_c('template',{slot:"title"},[_vm._v(" Пользователи ")]),_c('template',{slot:"first-name"},[_vm._v(" Всего ")]),_c('template',{slot:"first-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.users.total)+" ")]),_c('template',{slot:"secondary-name"},[_vm._v(" Активно сейчас ")]),_c('template',{slot:"secondary-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.users.active)+" ")]),_c('template',{slot:"three-name"},[_vm._v(" Кол. пользователей приложений ")]),_c('template',{slot:"three-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.users.appsTotal)+" ")])],2)],1)],1),_c('r-row',{staticClass:"mb-6"},[_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.socialMedia.appName,"description":_vm.dataDashboard.socialMedia.departmentName,"icon":_vm.dataDashboard.socialMedia.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.socialMedia.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.socialMedia.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.socialMedia.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Открытых процессов ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.socialMedia.activeProcesses)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Завершенных процессов ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.socialMedia.completedProcesses)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.landRedistribution.appName,"description":_vm.dataDashboard.landRedistribution.departmentName,"icon":_vm.dataDashboard.landRedistribution.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landRedistribution.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landRedistribution.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.landRedistribution.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Открытых процессов ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landRedistribution.activeProcesses)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Завершенных процессов ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landRedistribution.completedProcesses)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.landProvision.appName,"description":_vm.dataDashboard.landProvision.departmentName,"icon":_vm.dataDashboard.landProvision.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landProvision.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landProvision.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.landProvision.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Открытых процессов ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landProvision.activeProcesses)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Завершенных процессов ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.landProvision.completedProcesses)+" ")])],2)],1)],1),_c('r-row',{staticClass:"mb-6"},[_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.violationPrevention.appName,"description":_vm.dataDashboard.violationPrevention.departmentName,"icon":_vm.dataDashboard.violationPrevention.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationPrevention.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationPrevention.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.violationPrevention.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Открытых процессов ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationPrevention.activeProcesses)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Завершенных процессов ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationPrevention.completedProcesses)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.violationRci.appName,"description":_vm.dataDashboard.violationRci.departmentName,"icon":_vm.dataDashboard.violationRci.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationRci.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationRci.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.violationRci.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Открытых процессов ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationRci.activeProcesses)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Завершенных процессов ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.violationRci.completedProcesses)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.hr.appName,"description":_vm.dataDashboard.hr.departmentName,"icon":_vm.dataDashboard.hr.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.hr.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.hr.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.hr.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Открытых процессов ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.hr.activeProcesses)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Завершенных процессов ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.hr.completedProcesses)+" ")])],2)],1)],1),_c('r-row',{staticClass:"mb-6"},[_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.attestation.appName,"description":_vm.dataDashboard.attestation.departmentName,"icon":_vm.dataDashboard.attestation.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.attestation.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.attestation.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.attestation.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Назначено аттестаций ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.attestation.plannedAttestations)+" ")]),_c('template',{slot:"bottom-right-name"},[_vm._v(" Пройдено аттестаций ")]),_c('template',{slot:"bottom-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.attestation.completedAttestations)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.emergencyAlert.appName,"description":_vm.dataDashboard.emergencyAlert.departmentName,"icon":_vm.dataDashboard.emergencyAlert.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.emergencyAlert.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.emergencyAlert.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.emergencyAlert.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Контролируемые показатели ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.emergencyAlert.monitoredIndicators)+" ")])],2)],1),_c('r-col',{attrs:{"cols":{
        widest: 4, wide: 4, middle: 6, narrow: 12,
      }}},[_c('apps-block',{attrs:{"loading":_vm.isLoading,"title":_vm.dataDashboard.dgh.appName,"description":_vm.dataDashboard.dgh.departmentName,"icon":_vm.dataDashboard.dgh.iconName}},[_c('template',{slot:"top-left-title"},[_vm._v(" Всего пользователей ")]),_c('template',{slot:"top-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.dgh.usersTotal)+" ")]),_c('template',{slot:"top-right-title"},[_vm._v(" Активных пользователей ")]),_c('template',{slot:"top-right-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.dgh.usersActive)+" ")]),_c('template',{slot:"center-name"},[_vm._v(" Последнее действие ")]),_c('template',{slot:"center-data"},[_vm._v(" "+_vm._s(_vm._f("dateDiff")(_vm.dataDashboard.dgh.userLastAccessTime))+" ")]),_c('template',{slot:"bottom-left-name"},[_vm._v(" Контролируемые показатели ")]),_c('template',{slot:"bottom-left-data"},[_vm._v(" "+_vm._s(_vm.dataDashboard.dgh.monitoredIndicators)+" ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }