<template>
  <div class="tags">
    <r-button-action
      class="tags__action-button mb-6"
      title="Добавить"
      @click="openAddModal"
      icon="add"
    />
    <div class="tags__search mb-8">
      <r-input
        v-model="searching"
        @input="debounceInput"
        label="Поиск"
        fill="rocky"
      />
    </div>
    <table-component
      :saving="saving"
      :loading="isLoadPage"
      :columns="columns"
      empty-details="Вы можете добавить новую метку"
      :list="tagsList"
      @edit="editForm"
      @delete="deleteItem"
      :save-button="false"
      :sort-mode="true"
      :sort-fields="{
        name: true,
        updatedAt: true,
        'tagCategory.name': true,
        'tagCategory.description': true,
      }"
    >
      <template #rowData="{ item, column }">
        <div
          v-if="column.key === 'updatedAt'"
          class="tags__table-item"
        >
          <p class="feta mb-1">
            {{ item[column.key] | dateMonthYear }}
          </p>
          <p class="mozarella anie">
            {{ item[column.key] | time }}
          </p>
        </div>
        <div
          v-else-if="column.key === 'color'"
          class="tags__table-item"
        >
          <div
            class="circle"
            :style="`background-color: ${item[column.key]}`"
          />
        </div>
        <div
          v-else-if="column.key === 'tagCategory.name'"
          class="tags__table-item"
        >
          {{ item.tagCategory.name }}
        </div>
        <div
          v-else-if="column.key === 'tagCategory.description'"
          class="tags__table-item"
        >
          <r-shorter
            :text="item.tagCategory.description || '—'"
            :count-row="3"
            :tooltip-options="{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }"
          />
        </div>
        <div
          v-else
          class="tags__table-item"
        >
          <r-shorter
            :text="item[column.key] || '—'"
            :content-class="null"
            :count-row="1"
            :tooltip-options="{
              position: 'center-left',
              alignTitle: 'start',
              maxWidth: '300px',
            }"
          />
        </div>
      </template>
    </table-component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TableComponent from '@/components/TableComponent';
import Tag from '@/structure/entity/Tag';
import FormTagModal from './components/FormTagModal';
import RemoveSubmitModal from './components/RemoveSubmitModal';
import { debounce } from '@/plugins/lib';

export default {
  name: 'Tags',
  components: {
    TableComponent
  },
  data: () => ({
    isLoadPage: false,
    searching: null,
    columns: [
      {
        title: 'Наименование',
        key: 'name',
        font: 'bryndza',
        width: 2
      },
      {
        title: 'Цвет',
        key: 'color',
        font: 'mozzarella',
        width: 1
      },
      {
        title: 'Категория меток',
        key: 'tagCategory.name',
        font: 'mozzarella',
        width: 2
      },
      {
        title: 'Описание',
        key: 'tagCategory.description',
        font: 'mozzarella',
        width: 3
      },
      {
        title: 'Изменён',
        key: 'updatedAt',
        font: 'mozzarella',
        width: 2
      }
    ],
    saving: false
  }),
  computed: {
    ...mapState('tags', ['tags']),
    tagsList() {
      return this.tags?.list || [];
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions('tags', ['changeTagsList', 'getTagsList']),
    async loadData(payload) {
      this.isLoadPage = true;
      await this.getTagsList(payload);
      this.isLoadPage = false;
    },
    async openAddModal() {
      await this.$rir.modal.open(FormTagModal, {
        item: new Tag(),
        title: 'Новая метка',
        button: 'Добавить'
      });
      this.loadData();
    },
    async editForm(item) {
      await this.$rir.modal.open(FormTagModal, {
        item,
        button: 'Редактировать'
      });
      this.loadData();
    },
    async deleteItem(item) {
      await this.$rir.modal.open(RemoveSubmitModal, { item });
      this.loadData();
    },
    debounceInput: debounce(function () {
      this.loadData({ search: this.searching });
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>

.circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.tags {
  &__report {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  &__table {
    &-item {
      width: 100%
    }
  }

  &__search {
   // padding: 0 32px;
  }

  &__action-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
   // padding: 0 32px;

    span {
      color: var(--rir-rocky)
    }

    &:hover {
      &::v-deep {
        & path {
          fill: var(--rir-rocky--hover);
        }
      }

      span {
        color: var(--rir-rocky--hover);
      }
    }
  }

  &__reaction-time {
    padding-right: 16px;
  }
}
</style>
