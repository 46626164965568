<template>
  <div class="toolbar-entity">
    <r-button-action
      @click.native="goBack"
      title="Назад"
      icon="arrow-left"
    />
    <user-info />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import UserInfo from '@/layout/components/UserInfo';

export default {
  name: 'ToolbarEntity',
  components: { UserInfo },
  methods: {
    ...mapMutations('directories', ['setProperty']),
    goBack() {
      this.$router.back()
      /*if (this.$route.params.id) {
        const path = this.$route.path?.split('/');
        this.$router.push(`/${path[1]}`);
        const { tab } = this.$route.meta;
        if (tab) this.setProperty({ name: tab});
      }*/
    }
  }
};
</script>

<style lang="scss" scoped>
.toolbar-entity {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__back {
    display: block;
    line-height: 20px;
    color: var(--rir-rocky);
    cursor: pointer;
    background: none;
    border: none;

    &:hover {
      color: var(--rir-rocky--hover);

      &::v-deep {
        & path {
          fill: var(--rir-rocky--hover);
        }
      }
    }

    &-icon {
      margin-right: 9px;
    }
  }
}
</style>
