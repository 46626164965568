<template>
  <div class="form-keyword-modal">
    <h2 class="nomira mb-8 camembert">
      {{ title }}
    </h2>
    <section class="d-flex flex-direction-column">
      <r-input
        label="Наименование"
        v-model.lazy="$v.model.name.$model"
        :error="model.name ? $v.model.name.$error : false"
        :error-message="mesErr($v.model.name)"
      />
      <r-button
        @click="save"
        class="mt-8"
        width="wide"
        :title="button"
        :disabled="!model.name"
      />
    </section>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';
import validateMixin from '@/plugins/vuelidate/validateMixin';
import { regEx, required } from '@/plugins/vuelidate/customValidate';
import Keyword from '@/structure/entity/Keyword';

export default {
  name: 'FormKeywordModal',
  mixins: [validationMixin, validateMixin],
  props: {
    item: {
      type: Object
    },
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'Сохранить'
    },
    action: {
      type: String,
      default: 'addKeyword' // 'EDIT'
    }
  },
  data: vm => ({
    model: new Keyword(vm.item)
  }),
  validations: {
    model: {
      name: {
        required: required('Обязательное поле'),
        regEx: regEx(/^[\w\dа-яА-Я-]*$/, 'Без пробелов и спец. символов (разрешено только "-")')
      }
    }
  },
  methods: {
    ...mapActions('keywords', ['addKeyword', 'editKeyword']),
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.saving = true;
      await this[this.action](this.model)
        .then(() => {
          this.$rir.notification.send({
            title: this.action === 'editKeyword' ? 'Ключевое слово успешно изменено' : 'Добавлено новое ключевое слово',
            seconds: 10,
            iconOptions: {
              fill: 'matrix',
              icon: 'warning'
            }
          });
          this.$rir.modal.close();
        })
        .finally(() => {
          this.saving = false;
        });
      this.$rir.modal.close({ ...this.model.getApiData, ...this.model });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-keyword-modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  &__wrapper {

  }
}
</style>
